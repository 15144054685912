.btn{
   @include media(down, $breakpoint-md){
      font-size: 14px;
   }
   &-link{
      padding: 0;
   }
   &-primary{
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 2;
      overflow: hidden;
      text-transform: uppercase;
      &::after{
         position: absolute;
         content: "";
         width: 100%;
         height: 0;
         bottom: 0;
         left: 0;
         z-index: -1;
         border-radius: inherit;
       //  background: rgba($white, 0.2);
         transition: all .3s ease;
      }
      &:hover{
         background-color: $primary;
         border-color: $white;
         .price-box & {
            border-color: $primary;
         }
         &::after{
            top: 0;
            height: 100%;
         }
      }
   }
   &-lg{
      font-size: size(30);
      font-weight: $font-medium;
      text-shadow: none;
      border-radius: 11px;
   }
   &-small{
      padding: 10px 15px;
   }
   &.large-x-space{
      padding-left: size(40);
      padding-right: size(40);
   }
   &.shadow-none{
      text-shadow: none;
   }
   &-light{
      color: $primary;
      border-radius: 26.407px;
      background: $white;
      padding: 10px 26px;
      font-size: 16px;
      font-weight: 400;
      transition: all 0.5s ease;
      box-shadow: none !important;
      
      &.nohover:hover{
         background: $white;
         color: $primary;
      }
      &:not(.nohover):hover{
         background: transparent;
         border-color: $white;
   
            color: $white;
      }

      @include media(down, $breakpoint-md){
         font-size: 14px;
      }
   }
}
.form-control{
   min-height: size(50);
   &.form-input-md{
      min-height: 60px;
      @include media(down, $breakpoint-sm){
         min-height: 40px;
         border-radius: 12px;
      }
   }
}



.custom-checkbox {
   display: block;
   margin-bottom: 15px;
   input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
      &:checked{
         + label{
            &:after{
               content: '';
               display: block;
               position: absolute;
               top: 2px;
               left: 9px;
               width: 6px;
               height: 14px;
               border: solid $primary;
               border-width: 0 2px 2px 0;
               transform: rotate(45deg);
            }
         }
      }
    }
    label {
      position: relative;
      cursor: pointer;
      color: rgba(255,255,255,0.4);
      &::before{
         content:'';
         -webkit-appearance: none;
         background-color: $white;
         border: 2px solid $primary;
         box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
         padding: 10px;
         display: inline-block;
         top: -2px;
         position: relative;
         vertical-align: middle;
         cursor: pointer;
         margin-right: 20px;
      }
    }
    
 }


 .em-group{
   margin-bottom: 20px;
   .form-control{
      border-top: 0;
      border-left:0;
      border-right:0;
      &:focus{
         background: transparent;
      }
   }
   .em-label{
      margin-top:5px ;
   }
 }


 .bottom-container-fixed{
   max-width: 650px;
   width: 100%;
   margin: 0 auto;
   text-align: center;
 }
 
 .dark-select-box {
   border-radius: 6px;  
   height: 45px;
   font-size: 16px;
   &:focus{
      box-shadow: none;
      border-color: #3E3E3E;
   }
   option{
      background-color: #191919;
      color: $white;
      &:hover{
         background-color: #191919;
      }
   }
 }
 .sm-form{
   min-height: 45px;
   font-size: 16px;
   color: $white;
   border-radius: 6px;
 }
 