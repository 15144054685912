/* =========== IMAGE PATH VARIABLE ============= */

$assetPath: "/assets/images";

/* Usage of bacground image */

//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;
// Color system
$white: #fff; // added
$black: #060606; // added
$primary: #7015FF; // added
$secondary: #1B1B1B;
$secondaryLight: #1E1E1E;
$success: #47B872;
$info: #17a2b8;
$warning: #fd7e14;
$danger: #ED3E3E;
$light: $white;
$dark: #181818;
$gray: #A5A6B6;
$light-gray: #3E3E3E;
$light-yellow: #f9e3cc;
$darkheader-bg: #0D0D0D;
$charcoal: #33383D;
$gray-shade2: #d6dbd8;

$dark-shade1: #A39E9E;
$grey-block: #F5F7F9;
$svart: #191919;
$blue: #2B67EC;
$grey-neutral-100: #DCE1DE;
$green: #08A045;

$theme-colors: (
  "white": $white,
  "black": $black,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "gray": $gray,
  "light-gray": $light-gray,
  "light-yellow": $light-yellow,
  "charcoal": $charcoal,
  "svart": $svart,
  "grey-block": $grey-block,
  "blue": $blue,
  "grey-neutral-100": $grey-neutral-100,
  "green": $green
);
$utilities: (
  "opacity": (property: opacity,
    values: (0: 0,
      25: .25,
      50: .5,
      60: .6,
      70: .7,
      75: .75,
      100: 1,
    )),
);

// Badges 
$badge-primary: #F3C32F;
$badge-success: $success;
$badge-secondary: $gray;
$badge-danger: $danger;
$badge-dark: $gray;
// Modal 
$modal-content-bg: $dark;
$modal-content-border-color: rgba($white, .2);
$modal-content-border-radius: 0.625rem;
$modal-sm: 317px;
// Body
// Settings for the `<body>` element.
$body-bg: #191919;
$body-color: $light;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1130px) !default;
// 1310px
// Links
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($primary, 7%
);
$link-hover-decoration: none;
// Font
$font-family-base: 'Clash Grotesk';
$font-heading: $font-family-base;
$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
$font-lighter: lighter;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: bolder;
$line-height-base: 1.375;
$font-custom: 16;
$font-75: $font-size-base * 4.6875;
$font-20: $font-size-base * 1.25; //20px
$font-size-sm: $font-size-base * .875; //14px
$font-18: $font-size-base * 1.125;
$font-17: 1.0625rem;
$font-13: .813rem;
$font-14: .875rem;
$font-15: .9375rem;
$font-22: 1.375rem;
$font-24: 1.5rem;
$font-26: 1.625rem;
$font-26: 1.625rem;
$font-32: 2rem;
$font-34: 2.125rem;
// Style p element.
$paragraph-margin-bottom: 1rem;
// Media BreakPoint
$breakpoint-xxxxxl: 2400px;
$breakpoint-xxxxl: 1900px;
$breakpoint-xxxl: 1600px;
$breakpoint-2xxl: 1440px;
$breakpoint-xxl: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-xsl: 1199px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 414px;
$breakpoint-xxs: 375px;
// Components
// Heading
$headings-font-weight: $font-bold;
// Define common padding and border radius sizes and more.
$border-width: 1px;
$border-color: rgba($light, .5);
$border-radius: .25rem;
$border-radius-lg: .3rem;
$border-radius-sm: .2rem;
$rounded-pill: 50rem;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-0: none;
$caret-width: .3em;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: $caret-width * .85;
$transition-base: all .15s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;
// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 28px;
$btn-font-family: $font-family-base;
$btn-font-size: $font-size-base;
$btn-line-height: 1.3333;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-border-radius: 11px;
$btn-font-weight: $font-regular;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.5rem;
$btn-font-size-sm: $font-size-sm;
$btn-padding-y-lg: 10px;
$btn-padding-x-lg: 18px;
$btn-font-size-lg: 1.125rem;
// Forms
$form-group-margin-bottom: 2rem;
$label-margin-bottom: 5px;
$form-label-font-size: 14px;
$form-label-color: $light;
$input-font-family: $font-family-base;
$input-font-size: 20px;
$input-font-weight: $font-regular;
$input-padding-y-sm: 18px;
$input-padding-x-sm: 18px;
$input-font-size-sm: $font-size-sm;
$input-padding-y-lg: 0.75rem;
$input-padding-x-lg: 1rem;
$input-font-size-lg: $font-custom;
$input-bg: transparent;
$input-btn-border-width: 1px;
$input-border-color: $light-gray;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-focus-bg: rgba($light, 0.05);
$input-height: 36px;
$input-padding-y: 0.625rem;
$input-padding-x: 1.25rem;
$input-color: $white;
$input-font-weight: $font-regular;
$input-placeholder-color: rgba($light, 0.4);
$input-border-radius: 0;
$enable-negative-margins: true;
// Cards
$card-border-width: 0;
$card-bg: $dark;
$card-border-radius: 0.625rem;
$card-spacer-x: 1.25rem;
$card-spacer-y: 1.25rem;
// Progress bar
$progress-height: 10px;
// $progress-bg: transparent;
$progress-border-radius: 6px;
$progress-box-shadow: 0;
$progress-bar-bg: #111111;
// Offcanvas
$offcanvas-bg-color: $dark;
$offcanvas-padding-y: 15px;
$offcanvas-padding-x: 15px;
$offcanvas-border-width: 0;
// Accordion
$accordion-button-color: $light;
$accordion-button-padding-y: 0;
$accordion-button-padding-x: 0;

$tooltip-bg: #111111;
$tooltip-border-radius: 1rem;
$tooltip-color: $white;
$tooltip-padding-y: 5px;
$tooltip-padding-x: 18px;
$tooltip-font-size: 12px;
$tooltip-opacity: 1;

$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#999' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
;

//Gradiant Style
$fallback: $primary;
$from: $primary;
$to: darken($primary, 10);
$direction: 225deg;
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: true !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;
$grid-gutter-width: 1.875rem !default;

$table-hover-bg: $dark;
$table-hover-color: $white;

$pagination-bg: $white;
// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
);
$spacers: map-merge((0: 0,
      1: ($spacer * 0.25),
      2: ($spacer * 0.375),
      3: ($spacer * 0.5),
      4: ($spacer * 0.625),
      5: ($spacer * 1),
      6: ($spacer * 1.125), // 18
      7: ($spacer * 1.25), // 20
      8: ($spacer * 1.5),
      9: ($spacer * 1.75),
      10: ($spacer * 1.875),
      11: ($spacer * 2),
      12: ($spacer * 2.125),
      13: ($spacer * 2.375),
      14: ($spacer * 3.5),
      15: ($spacer * 3.875),
      16: ($spacer * 4.125),
      17: ($spacer * 6.25),
      18: ($spacer * 9.375),
      2px: 2px,
      4px: 4px,
      8px: 8px,
      10px: 10px,
      12px: 12px,
      16px: 16px,
      18px: 18px,
      20px: 20px,
      30px: 30px,
      40px: 40px,
      80px: 80px,
      100px: 100px,
    ), $spacers);
//Custom spacers
// $spacer: 1rem;
// $spacers: ( 0:0);
// @for $i from 1 through 10 {
//   $spacers: map-merge($spacers, (
//   $i: ($i * $spacer/4)
//   ));
// }