h1, .h1{font-size: size(38);}
h2, .h2{font-size: size(32);
   @include media(down, $breakpoint-xxl){
      font-size: size(28);
   }
}
h3, .h3{font-size: size(28);
   @include media(down, $breakpoint-xxl){
      font-size: size(22);
   }
}
h4, .h4{font-size: size(24);
}
h5, .h5{font-size: size(20);
  
}
h6, .h6{font-size: size(18);
   @include media(down, $breakpoint-xxl){
      font-size: size(16);
   }
}

.font-64{
   font-size: var(--font64);
}
.fs-16{
   font-size: var(--font16) !important;
}
.fs-20{
   font-size: var(--font20);
}
.fs-24{
   font-size: 24px;
}
.font-32{
   font-size: var(--font32);
}
.font-40{
   font-size: var(--font40);
}