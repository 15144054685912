.navbar-brand {
  flex-shrink: 0;
  width: 220px;

  svg {
    fill: #fff;
    width: 105px;
    height: 48px;
    @include media(down, $breakpoint-sm) {
      width: 78px;
      height: 36px;
    }

    &:hover {
      fill: $primary;
    }
  }
}

.black_header {

  svg {
    fill: #000 !important;

    &:hover {
      fill: $primary !important;
    }
  }
}

.navlink {
  color: $white !important;
  font-weight: 300;

  &:not(:last-child) {
    margin-right: size(35);

    @include media(down, $breakpoint-xl) {
      margin-right: size(30);
    }

  }

  @include media(down, $breakpoint-sm) {
    margin-right: 0;
    font-size: 18px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:hover {
    color: $primary !important;
  }
}

.black_header {
  .navlink {
    color: #101010 !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    font-style: normal !important;
  }
}

.black_header {
  .link-hover {
    &:hover {
      color: $primary !important;
    }
  }

  .btn-primary {
    &:hover {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }

  .btn-light {
    &:hover {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
}

.navbar-brand {
  max-width: 140px;

  @include media(down, $breakpoint-md) {
    max-width: 120px;
  }
}

.main-header.fixed-header {
  background: $darkheader-bg;
  padding: 0;
  position: fixed;
  top: -80px;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  z-index: 102;
  left: 0;
  right: 0;
  border-bottom-color: transparent !important;

  .navbar {
    padding: 25px 0 !important;
  }
}

.navbar-toggler {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 20px;
  order: 2;
  z-index: 1046;

  .navbar-light & {
    border: none;
    box-shadow: none;
  }
}

.headeraccount-link {
  margin-left: size(46);

  @include media(down, $breakpoint-xl) {
    margin-left: size(30);
  }

  @include media(down, $breakpoint-sm) {
    order: 1;
    margin-left: auto;
  }

  a {
    &:not(:first-child) {
      margin-left: size(28);

      @include media(down, $breakpoint-xl) {
        margin-left: 15px;
      }
    }
  }

  .icon-ic-checkout {
    font-size: 16px;
  }
}

.hamburger-btn {
  width: 95px;
  height: 90px;
  background: $dark;
  border-radius: 0;
  margin-right: 44px;
  box-shadow: none !important;
  outline: 0 !important;

  @include media(down, $breakpoint-xxl) {
    width: 80px;
  }


  @include media(down, $breakpoint-md) {
    display: none !important;
  }

  span {
    width: 43px;
    height: 1px;
    display: block;
    background-color: $white;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    transition: .25s ease-in-out;

    @include media(up, $breakpoint-md) {
      .sidebar-active & {
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(1) {
          -webkit-transform: rotate(45deg) translate(3px, -1px);
          -moz-transform: rotate(45deg) translate(3px, -1px);
          -o-transform: rotate(45deg) translate(3px, -1px);
          transform: rotate(45deg) translate(3px, -1px);

        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg) translate(-5px, 9px);
          -moz-transform: rotate(-45deg) translate(-5px, 9px);
          -o-transform: rotate(-45deg) translate(-5px, 9px);
          transform: rotate(-45deg) translate(-5px, 9px);

        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

  }
}

// Starts css for Side bar
.menu-text {
  display: block;
  text-transform: capitalize;
  // padding-left: size(20);
  background-clip: padding-box;
  zoom: 1;
  color: $white;

  position: relative;
  @extend .fs-20;
  font-weight: 500;
  transition: all 0s ease;
  transition-delay: 0;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
  }

  @include media(up, $breakpoint-md) {
    .sidebar-active & {
      opacity: 1;
      visibility: visible;
      position: relative;
      transition: all 0.25s ease;
      transition-delay: 0.15s;
    }
  }
}

.sidebar-menu {
  width: 288px;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 33px 0;
  z-index: 9;
  transition: width 0.2s ease;
  transition: transform 0.4s;

  .logobox {
    padding: 0px 30px;
    position: relative;
    z-index: 19;
  }

  @include media-breakpoint-down(lg) {
    z-index: 9999;
    background: #000;
    width: 100%;
    max-width: 100%;
    transform: translateX(-100%);
    overflow: hidden;
    width: 100%;

  }

  ul {
    background: transparent;
    position: relative;
    padding: 0 30px;
    z-index: 10;
    overflow: auto;
    height: calc(100vh - 241px);

    @include media-breakpoint-down(xl) {
      height: calc(100vh - 192px);
    }

    @include media-breakpoint-down(lg) {
      height: calc(100vh - 172px);
    }
  }

  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 438px;
    bottom: 0;
    background: rgba(112, 21, 255, 0.4);
    filter: blur(349.5001525879px);
    pointer-events: none;
  }

  @include media(up, $breakpoint-md) {
    &.sidebar-active {
      width: 300px;
    }
  }

  @include media(down, $breakpoint-lg) {
    &.sidebar-active {
      transform: translateX(0);
      width: 100% !important;

      &~.overlay-menu {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(10, 8, 13, 0.60);
        z-index: 999;
        filter: blur(4px);
      }
    }

    // width: 100%;
    // padding: 10px;
    // top: auto;

    // ul {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-bottom: 0;
    // }
  }

  li {
    @include media(up, $breakpoint-md) {
      margin-bottom: 5px;
    }
  }
}

.page-block {
  @include media-breakpoint-up(lg) {
    margin-left: 288px;
  }

  // margin-right: size(58);
  // margin-top: 114px;
  // @include media(down, $breakpoint-xxl){
  //   margin-right: size(30);
  //   margin-left: calc(80px + 1.875rem);
  // }
  // @include media(down, $breakpoint-md){
  //   margin-left: 15px;
  //   margin-right: 15px;
  //   margin-bottom: 70px;
  //   margin-top: 81px;
  // }
  // @include media(down, $breakpoint-sm){
  //   margin-top: 61px;
  // }
}

.header-wrap {
  background-color: $body-bg;
  padding-right: size(50);
  justify-content: space-between;

  @include media(down, $breakpoint-md) {
    padding: 14px 15px;
  }



}

.menu-link {
  display: flex;
  align-items: center;
  color: rgba($white, 0.65) !important;
  font-size: 30px;
  margin: 5px 0;
  height: 40px;
  transition: color 0.15s;

  &:after {
    content: '';
    display: block;
    border-radius: 4px;
    background: #7015FF;
    position: absolute;
    width: 20px;
    height: 24px;
    left: -50px;
    transition: left 0.2s;
  }

  &:hover {
    color: $white !important;

    &:after {
      left: -15px;
    }
  }


  @include media(down, $breakpoint-md) {
    // padding: 5px;
    // width: 30px;
    // height: 30px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  &.active {
    span {
      opacity: 1 !important;
    }
    position: relative;
    &:after {
      left: -45px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      right: -10px;
      top: 0;
      bottom: 0;
      background: linear-gradient(92deg, rgba(112, 21, 255, 0.71) 2.13%, rgba(70, 70, 70, 0.40) 101.06%);
      border-radius: 8px;
      border: 1px solid rgba(220, 225, 222, 0.60);
      opacity: 0.3;
      backdrop-filter: blur(20px);
    }
  }
}

.search-form {
  max-width: 389px;

  @include media(down, $breakpoint-sm) {
    max-width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 1px solid #3E3E3E;
    border-bottom: 1px solid #3E3E3E;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    position: absolute;
    background-color: #202020;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    form {
      margin: -1px 0;
    }

    .search-close {
      width: 52px;
      padding: 18px;
      height: 52px;
    }
  }

  .form-control {
    font-size: $font-size-base;
    padding: size(12) 50px size(12) size(30);
    height: 52px;
    border-radius: 45px;
    background: #202020;

    @include placeholder {
      font-style: italic !important;
    }

    @include media(down, $breakpoint-sm) {
      border-radius: 0;
      height: 54px;
      font-size: 14px;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

.search-button {
  width: 32px;
  @extend %flex;
  right: 20px;
  top: 15px;
}

.header-buttons {
  li {
    margin: 0 size(6);

    &:last-child {
      margin-right: 0
    }
  }

  .header-blocklink {
    @extend %flex;
    width: 32px;
    height: 32px;
    font-size: 24px;
    color: #828282;
    transition: color 0.2s ease;

    &:hover {
      color: $primary;
    }
  }
}

.notif-count {
  position: absolute;
  min-width: 14px;
  height: 14px;
  font-size: 10px;
  color: $white;
  font-weight: $font-bold;
  background: #7015FF;
  @extend %flex;
  @include border-radius(50%);
  top: 0px;
  right: 0;
}

.is-not-login {
  margin-top: 0px !important
}



.close-btn {
  display: none;

  @include media(down, $breakpoint-md) {
    display: block;
    position: absolute;
    right: 30px;
    top: 30px;

    span {
      font-size: 20px;
    }

  }

  @include media(down, $breakpoint-sm) {

    top: 15px;


  }
}

.uc-dropdown {
  padding: 0;
  margin: 0 0.375rem;

  .icon-user {
    font-size: 24px;
    line-height: 28px;
  }
}

.header-toggle-menu {
  .btn-primary {
    background-color: transparent !important;
    border: 0;
    padding: 6px;
  }

  .dropdown-menu {
    background-color: #fdfdfd;
    border-radius: 16px;
    min-width: 220px;
    margin-top: 10px;

    .dropdown-item {
      background-color: transparent;
      color: $black;
      font-size: 20px;

      &:hover {
        color: $primary !important;
        transition: 0.3s all;
      }
    }
  }
  &:hover{
    rect{
      fill: $primary;
      transform: 0.4s all;
    }
  }
}