:root {
  --font16: 16px;
  --font20: 20px;
  --font32: 32px;
  --font40: 40px;
  --font64: 64px;

  --py-60: 200px 0 80px 0;
  --py-100: 120px 0px 80px 0;
  --py-80: 80px 0 0px 0;
  --boxLeftWidth: calc(100% - 115px);
  --boxRightWidth: 115px;

  @include media(down, $breakpoint-lg) {
    --font64: 50px;
    --py-60: 170px 0 70px 0;
    --py-100: 105px 0px 60px 0;
  }

  @include media(down, $breakpoint-md) {
    --font64: 45px;
    --font16: 14px;
    --font20: 16px;
    --font16: 12px;
    --font20: 14px;
    --font32: 18px;
    --font40: 20px;
    --font64: 36px;
    --py-60: 100px 0 50px 0;
    --py-100: 85px 0px 30px 0;
    --py-80: 40px 0 0px 0;
  }

  @include media(down, $breakpoint-sm) {
    --py-60: 60px 0 30px 0;
    --py-100: 70px 0px 20px 0;
    --py-80: 20px 0 0px 0;
    --font16: 11px;
  }
}

.greypage-block {
  background: #dce1de;

  &:not(.white-link) {
    header {
      .navlink {
        color: $darkheader-bg !important;
      }

      .navbar-brand {
        filter: invert(1);
      }
    }
  }
}

.greypage-block-education {
  // background: #dce1de;
  background: #f5f7f9;


  &:not(.white-link) {
    header {
      .navlink {
        color: $darkheader-bg !important;
      }

      .navbar-brand {
        filter: invert(1);
      }
    }
  }
}

.header-border-none {
  header {
    border-bottom: 0 !important;
  }
}

.main-wrapper {
  color: #191919;
}

.letter-spacing {
  letter-spacing: 3.6px;
}

.font-24 {
  font-size: 24px;

  @include media(down, $breakpoint-sm) {
    font-size: 16px;
  }
}

.margin-b-16 {
  margin-bottom: 16px;
}

.bg-darkblock {
  background: #0D0D0D;
  border-radius: 20px;
  border: 1px solid rgb(255 255 255 / 20%);
  line-height: 1;

  >* {
    &:not(.btn):not(.no-border-p):not(.border-bottom-none) {
      padding-bottom: 18px;
      margin-bottom: 18px;
       border-bottom: 1px solid #dce1de;
    }
  }

  &.dark>* {
    &:not(.btn) {
      border-color: $white;
    }
  }
  &.dark{
    .year_P{
      color: $white;
    }
  }
  >.btn {
    border-radius: 20px;
    font-size: 22px;
    font-weight: 400;
    padding: 10px;
    margin-top: 16px;
    @include media(down, $breakpoint-sm) {
      font-size: 16px;
    }
  }

  ul {
    li {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    margin-bottom: 18px;
  }
}

.p-32 {
  padding: 32px;
}

.content-block {
  width: calc(100% - 461px);
  padding-right: 75px;

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 0px !important ;
  }
}

.accordion-dark {

  .accordion-button,
  .accordion-body {
    color: #fff !important;
  }

  .accordion-item {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #bababa;
    border-radius: 0;
  }

  // .accordion-button::after {
  //   filter: invert(1);
  // }
}

.education-block {
  padding-top: 80px;
  color: #191919;
}

.text-darkblock {
  color: #191919;
}

.text-lightblock {
  color: #dce1de;
}

.header-border-none {
  header {
    border: 0 !important;
  }
}

.homeservice-block {
  .container {
    margin-top: -34px;
    position: relative;
  }
}

.slider-blockwrap {
  padding: 80px 0 120px;

  @include media(down, $breakpoint-sm) {
    padding: 40px 0 20px 0;
  }

  .feature-box {
    background: transparent;
    cursor: pointer;

    &.active {
      background: #dce1de;

      p {
        color: #7015ff;
      }
    }
  }
}

.price-box {
  padding: 35px 32px;
  background-color: $darkheader-bg;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #F5F7F9;
  border-radius: 20px;
  width: 295px;

  @include media-breakpoint-down(md) {
    text-align: center;
    padding: 36px;
  }

  &.active {
    border: 1px solid #dce1de;
    background: var(
      --gradient,
      linear-gradient(167deg, #191919 1.56%, #7015ff 31.53%, #191919 98.27%)
    );
    color: #fff;
    .btn-primary {
      border-radius: 26.407px;
      background: linear-gradient(243deg, #0b6e4f 0%, #08a045 100%) !important;
      border: 1px solid transparent !important;
      color: #fff !important;
      min-height: 44px;

      &:hover {
        color: #fff !important;
        border: 1px solid $white;
      }
    }
    .btn-gradient {
      border-radius: 26.407px;
      font-size: 16px;
      font-weight: 400;
      padding: 10px 26px;
      min-height: 44px;
    }

    .btn-secondary {
      font-size: 16px;
      font-weight: 400;
      padding: 10px 26px;
    }

    .btn-secondary {
      border-radius: 26.407px;
      padding: 8px 3px;
      background: $white;
      min-height: 44px;

      color: #7015ff !important;

      &:hover {
        color: $white !important;
        border: 1px solid $white;
        background: transparent;
      }
    }

    .btn-secondary span {
      color: #7015ff !important;
      &:hover {
        color: $white !important;
      }
    }

    * {
      color: #fff !important;
    }
  }

  h3 {
    color: #F5F7F9;
    margin-bottom: 18px;
  }

  >span {
    margin-bottom: 32px;
    display: block;
  }

  .price-list {
    list-style: none;

    margin-bottom: 0;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      margin-left: -10px;
      margin-right: -10px;
      min-height: 176px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 18px;
    }

    li {
      font-weight: 300;
      color: #F5F7F9;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.listing-ul {
  padding-left: 0;

  li {
    display: flex;
    align-items: center;

    &::before {
      width: 25px;
      content: "";
      display: block;
      height: 26px;
      background: url("../../../../public/assets/images/Ok.svg") no-repeat center;
      flex-shrink: 0;
      margin-right: 8px;
      align-self: center;
    }
  }
}

.price-boxtext {
  gap: 7px;
  margin-bottom: 28px;
}

// .font-64 {
//   font-size: 64px !important;
// }
.font-10 {
  font-size: 10px;
}

.pricing-section {
  margin: auto;
  padding: 40px 0;

  @include media-breakpoint-down(lg) {
    width: 100% !important;
  }

  @include media(down, $breakpoint-md) {
    padding: 0;
    width: 100% !important;
  }

  .padding-15 {
    padding: 15px; 
    height: 100%;
    @include media-breakpoint-down(md) {
      padding: 15px 10px;
    }
  }
  .price-box{
    height: 100%;
  }
}

.infoblock-section {
  padding: 150px 0;
}

.mt-120 {
  margin-top: 120px;

  @include media-breakpoint-down(lg) {
    margin-top: 100px;
  }
}

#videoIframe {
  max-width: inherit;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.pb-80 {
  padding-bottom: 117px;
}

.round-ic {
  width: 36px;
  height: 36px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.priceinfobox {
  padding: 15px 32px 13px;
  border-radius: 20px;
  border: 1px solid #191919;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s ease;

  @include media-breakpoint-down(md) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &:hover {
    background-color: #191919;
    color: #fff;
  }
}

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.pt-84 {
  padding-top: 120px;
}

.radius-20 {
  border-radius: 20px;
}

.border-black {
  border-color: #191919 !important;
}

.h-375 {
  height: 375px !important;
  @include media-breakpoint-down(sm) {
height: auto !important;
  }
}

.h-310 {
  height: 310px;
  @include media-breakpoint-down(sm) {
height: auto !important;
  }
}

button.btn-light.google-btn {
  height: 49px;
  font-size: 24px;
  color: #191919;
  font-weight: 500;
  border: none !important;

  @include media-breakpoint-down(md) {
    height: 32px;
    font-size: 16px;

    img {
      max-width: 14px;
    }
  }

  &:hover {
    color: #191919 !important;
    background-color: darken($color: #fff, $amount: 10%) !important;
  }
}

.or-text {

  &::before,
  &::after {
    content: "";
    display: block;
    width: 42%;
    height: 1px;
    background-color: #dce1de;
  }
}

.width-461 {
  width: 461px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.width-40block {
  width: 40%;
}


.btn-gradient {
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  border: 0;
  &::after{
    content: '';
    background: linear-gradient(75deg, #08a045 0%, #0b6e4f 100%);
    border: 0;
    transition: all 0.4s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  &::before{
    content: '';
    background: linear-gradient(266.05deg, #C9DBD5 2.28%, #239B53 63.64%);
    border: 0;
    transition: all 0.4s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  &:hover {
    color: $white !important;
    border-color: transparent;
    &::after{
      opacity: 0;
    }
    &::before{
      opacity: 1;
    }
  }
  >*{
    z-index: 1;
    position: relative;
  }
}

.blockinfo-wrap {
  padding: 40px;
  // gap: 40px;
  background: #191919;
  border-radius: 20px;
  /* Dropshadow */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  border: 1px solid rgb(255 255 255 / 20%);

  .btn-primary {
    &:hover {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }

  @include media(down, $breakpoint-md) {
    gap: 0 20px;
    padding: 16px;
  }
}

.teamimg-wrap {
  width: 286px;
  height: 283px;
  border-radius: 20px;
  // background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;

  // &::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 61.03%);
  //   pointer-events: none;
  //   opacity: 0.5;
  // }

  @include media(down, $breakpoint-lg) {
    width: 200px;
    height: 200px;

    .innerblock-page & {
      width: 48px !important;
      height: 48px !important;
      border-radius: 6px;
      box-shadow: none;
    }
  }

  @include media(down, $breakpoint-md) {
    width: 90px;
    height: 100%;
    border-radius: 6px;

  }
}

.education-mob-right{
  @include media(down, $breakpoint-md) {
    width: calc(100% - 110px);
  }
  .text-ellipse{
    @include media(down, $breakpoint-md) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.team-banner{
  h1{
    @include media(down, $breakpoint-sm) {
      font-size: 30px;
    }
  }
  &.banner-wrapper{
    min-height: 770px !important;
    @include media(down, $breakpoint-md) {
      height: auto !important;
      
    }
  }
  .banner-content-area{
    max-width: 943px;
    margin: 0 auto;
  }
}
.team-block-img-wrap{
  margin-top: -130px;
  z-index: 1;
  position: relative;
}
.team-block-img{
  width: 100%;
  max-width: 809px;
  height: 455px;
  border-radius: 82px;
  margin: 0 auto;
  position: relative;
  img{
    border-radius: 82px;
    z-index: 1;
    position: relative;
  }
  &::before{
    position: absolute;
    content: '';
    background: #7015FF;
    opacity: 0.5;
    filter: blur(107px);
    border-radius: 20px;
    z-index: 0;
    width: 678px;
    height: 393px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.small-thumbnail{
  width: 60px;
  height: 60px;
  img{
    border-radius: 8px;
  }
}
.inner-card{
  border: 1px solid rgb(245 247 249 / 20%);
  border-radius: 12px;
  padding: 16px;
  &:not(:last-child){
    margin-bottom: 16px;
  }
}
.blockleft-wrap {
  gap: 40px;
  // width: calc(100% - 285px);
  // width: (100%);


  @include media(down, $breakpoint-md) {
    width: auto;
  }

  @include media(down, $breakpoint-sm) {
    gap: 18px;
  }

  @include media(down, $breakpoint-sm) {
    gap: 18px;
  }
}

.teamcontent-wrap {
  padding-left: 40px;
  padding-right: 40px;
  @include media(down, $breakpoint-md) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.team-sm-tilte{
  @include media(down, $breakpoint-sm) {
    font-size: 11px;
  }
}
.team-main-title{
  @include media(down, $breakpoint-sm) {
    font-size: 16px;
  }
}

.blockinfo-content {
  line-height: 1.4;
}

.webinar-block-list{
  .blockright-wrap {
    width: 216px;
    @include media(down, $breakpoint-sm) {
      padding-bottom: 0;
    }
  }
  &.blockinfo-wrap{
    @include media(down, $breakpoint-sm) {
      padding: 20px;
    }
  }
}

.education-block-list{
  .blockright-wrap {
    @include media(down, $breakpoint-sm) {
      padding-bottom: 0;
    }
  }
}

.blockright-wrap {
  border-left: 1px solid #F5F7F9;
  padding-left: 40px;
  flex-shrink: 0;
  width: 285px;

  @include media(down, $breakpoint-md) {
    border: none;
    width: 100%;
    justify-content: end;
    display: flex;
    flex-direction: column;
    //  align-items: center;
    padding-left: 0px;
    padding-bottom: 80px;

    .mobile-left {
      @include media(down, $breakpoint-md) {
        position: absolute;
        bottom: 20px;
        left: 43px;
      }

      @include media(down, $breakpoint-sm) {
        left: 16px;
      }
    }
  }

  @include media(down, $breakpoint-md) {
    padding-bottom: 40px;
  }
}

.greypage-block {
  .footer {
    filter: invert(1);

    .link-hover {
      transition: 0s;

      &:hover {
        filter: invert(1);
      }
    }
  }
}

.individualeducation-block {
  // border-bottom: 1px solid #fff;

  .btn-primary {
    &:hover {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
}

.onboarding-page {
  //background: url("../../../../public/assets/images/banner-graphics.svg") no-repeat 100% 100%;
  background-size: contain;
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  // public/assets/images/Lilabakgrund.png
  //  background: url("../../../../public/assets/images/Lila\ bakgrund.png") no-repeat;
  background-size: cover;
  // padding: 50px 0;
  overflow: auto;

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  .header {
    padding: 40px 0;
  }

  &::after {
    right: 0px;
    top: 0px;
    bottom: 0px;
    // background: url("../../../../public/assets/images/Desktop.webp") no-repeat 100% 100%;
    background-size: contain;
    // @extend %boarding-image;

    @include media(down, $breakpoint-sm) {
      background: none !important;
      // opacity: 0.4;
    }
  }

  .container-fluid {
    position: relative;
    z-index: 1;
  }
}

.maxw-507 {
  max-width: 507px;
}

.margin-b-16 {
  margin-bottom: 10px;
}

.signup-form {
  .form-group {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

  .form-control {
    font-size: 20px;
    border-radius: 20px;
    border: 1px solid #dce1de;
    padding: 18px 100px 18px 18px;
    background: transparent;

    @include media-breakpoint-down(lg) {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 13px;
      height: 40px;
    }
  }

  .btn-light {
    font-size: 20px;
    padding: 18px 24px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 90px;

    @include media-breakpoint-down(md) {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.grey-link {
  opacity: 0.5;
  font-size: 16px;
}

.line-h-small {
  line-height: 1.2;
}

.radio-wrapper {
  gap: 2px;
  margin-top: -22px;
  display: flex !important;
  margin-bottom: 33px;

  >span {
    margin-right: 3px;

    @include media-breakpoint-down(md) {
      margin-right: 2px;
    }
  }

  .form-switch {
    color: rgba(255, 255, 255, 0.5) !important;
    display: flex;
    align-items: center;
    min-height: 15px;
    margin-bottom: 0;

    .form-check-label {
      margin-left: 5px;

      @include media-breakpoint-down(md) {
        margin-left: 3px;
      }
    }

    .form-check-input {
      width: 30px;
      height: 15px;
      background-color: #d9d9d9;
      margin-top: -1px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjMDlBMDQ2Jy8+PC9zdmc+) !important;
    }
  }
}

.pt-94 {
  padding-top: 94.5px;
}

.mt-16px {
  margin-top: 16px;
}

.btnlink-whitehover {
  transition: all 0.5s ease;

  &:hover {
    color: $white !important;
    opacity: 1 !important;
  }
}

.btnlink-hover {
  transition: all 0.5s ease;

  &:hover {
    color: $primary !important;
  }
}

.pt-69 {
  padding-top: 69px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.gap-40 {
  gap: 40px;

  @include media-breakpoint-down(md) {
    gap: 20px;
  }
}

.password-btn {
  width: 85px;
  height: 100%;

  &:hover {
    background: $primary !important;
    color: $white !important;
    border-color: $primary !important;
  }
}

.mobile-font-13 {
  @include media-breakpoint-down(lg) {
    font-size: 13px !important;
  }
}

.mobile-font-36 {
  @include media-breakpoint-down(lg) {
    font-size: 36px;
  }
}

.mobile-font-18 {
  @include media-breakpoint-down(lg) {
    font-size: 18px;
  }
}

.mobile-font-20 {
  @include media-breakpoint-down(lg) {
    font-size: 20px;
  }
}

.mobile-font-10 {
  @include media-breakpoint-down(lg) {
    font-size: 10px !important;

    .btnlink-hover {
      font-size: 10px !important;
      font-weight: 300 !important;
    }
  }
}

.mobile-font-8 {
  @include media-breakpoint-down(lg) {
    font-size: 8px !important;
  }
}

.mb-mobile-18 {
  @include media-breakpoint-down(lg) {
    margin-bottom: 18px !important;
  }
}

.mobile-pt-20 {
  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }
}

.mobile-full {
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.mobile-font-30 {
  @include media-breakpoint-down(md) {
    font-size: 30px !important;
  }
}

.white-bg-btn {
  background-color: #fff !important;
}

.linkblock {
  border-top: 1px solid transparent;
  padding-top: 4px;

  &.active {
    border-top: 1px solid #D9D9D9;
  }
}

.tabblock-wrap-in {
  display: none;
  border-bottom: 0 !important;

  &.active {
    display: block;
  }
}

.tabblock-wrap {
  @include media-breakpoint-down(md) {
    display: none;
    border-bottom: 0 !important;

    &.active {
      display: block;
    }
  }
}
.tab-courses{
  
  .linkblock{
    padding-bottom: 6px;
    color: rgb(255 255 255 / 60%);
    &.active{
      border-top: 0;
      border-bottom: 1px solid #D9D9D9;
      color: $white;
      @include media-breakpoint-down(md) {
        border-bottom: 0;
        border-top: 1px solid #D9D9D9;
      }
    }
  }
}
.tabblock-wrap-desk {
  @include media-breakpoint-up(md) {
    display: none;
    border-bottom: 0 !important;

    &.active {
      display: block;
    }
  }
  .link-hover{
    color: rgb(255 255 255 / 60%) !important;
    transition: 0.4s all;
    &:hover{
      color: $white !important;
    }
  }
  .tab-img{
    width: 280px;
    height: 470px;
  }
}

.mobile-border-none {
  @include media-breakpoint-down(md) {
    border: 0 !important;
  }
}

.mobile-space-0 {
  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

.mobile-width-full {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.lineheight-xs {
  line-height: 92%;
}

.image-blockwrap {
  @include media-breakpoint-down(md) {
    height: auto;
  }
}

.back-btnblock {
  &:hover {
    svg path {
      stroke: $primary;
    }
  }
}

.mobile-mb-20 {
  @include media-breakpoint-down(md) {
    margin-bottom: 20px !important;
  }
}

.mobile-pb-20 {
  @include media-breakpoint-down(md) {
    padding-bottom: 20px !important;
  }
}

.mobile-mb-14 {
  @include media-breakpoint-down(md) {
    margin-bottom: 14px !important;
  }
}

.mobile-mt-10 {
  @include media-breakpoint-down(md) {
    margin-top: 10px !important;
  }
}

.mobile-pt-0 {
  @include media-breakpoint-down(md) {
    padding-top: 0 !important;
  }
}

.mobile-pb-60 {
  @include media-breakpoint-down(md) {
    padding-bottom: 80px !important;
  }
}

.mobile-pb-100 {
  @include media-breakpoint-down(md) {
    padding-bottom: 100px !important;
  }
}

.mobile-pb-50 {
  @include media-breakpoint-down(md) {
    padding-bottom: 50px !important;
  }
}

.mobile-pb-96 {
  @include media-breakpoint-down(md) {
    padding-bottom: 126px !important;
  }
}

.mobile-pt-15 {
  @include media-breakpoint-down(md) {
    padding-top: 15px !important;
  }
}

.mobile-pt-40 {
  @include media-breakpoint-down(md) {
    padding-top: 34px !important;
  }
}

.mobile-mt-20 {
  @include media-breakpoint-down(md) {
    margin-top: 20px !important;
  }
}

.features-wrapper {
  @include media-breakpoint-down(md) {
    .slick-list {
      margin-top: -10px;
    }
  }
}

// .pricing-section {
//   .slick-list {
//     @include media-breakpoint-down(md) {
//       margin: -15px 0;
//     }
//   }
// }

.mobile-mb-0 {
  @include media-breakpoint-down(md) {
    margin-bottom: 0 !important;
  }
}

.mobile-pb-0 {
  @include media-breakpoint-down(md) {
    padding-bottom: 0 !important;
  }
}

.greysec-block {
  @include media-breakpoint-down(md) {
    padding: 30px 20px;
  }
}

.mobile-pt-40px {
  @include media-breakpoint-down(md) {
    padding-top: 40px !important;
  }
}

.mobile-gap-60 {
  @include media-breakpoint-down(md) {
    gap: 60px !important;
  }
}

.offcanvas.offcanvas-start {
  @include media-breakpoint-down(md) {
    width: 100% !important;
    padding: 30px 20px;
  }
}

.navbar-nav {
  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }
}

.mobile-first {
  @include media-breakpoint-down(md) {
    order: 0;
  }
}

.mobile-second {
  @include media-breakpoint-down(md) {
    order: 1;
  }
}

.mobilenavmenu {
  @include media-breakpoint-down(md) {
    width: 100%;
    // padding-bottom: 60px;
  }
}

a.mobile-navlink {
  @include media-breakpoint-down(md) {
    display: block;
    font-size: 20px !important;
    color: #dce1de !important;
    font-weight: 500 !important;
    opacity: 0.7;
    background-color: transparent !important;
    padding: 12px 0 !important;
    border: 0 !important;
    position: relative;
    border-bottom: 1px solid rgba(220, 225, 222, 0.3) !important;
    border-radius: 0 !important;
    text-align: left !important;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: block;
      left: auto;
      // background: url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g opacity="0.7"><path d="M9 6L15 12L918" stroke="white"/></g></svg>');
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cg opacity='0.7'%3e%3cpath d='M9 6L15 12L9 18' stroke='white'/%3e%3c/g%3e%3c/svg%3e");
    }
  }
}

.closemenu {
  display: none;
}

.navbar-toggler {
  &:not(.collapsed) {
    .hamburger-menu {
      display: none;
    }

    .closemenu {
      display: block;
    }
  }
}

.mobile-font-300 {
  @include media-breakpoint-down(md) {
    font-weight: 300;
  }
}

.mobile-font-600 {
  @include media-breakpoint-down(md) {
    font-weight: 600 !important;
  }
}

.mobile-font-16 {
  @include media-breakpoint-down(md) {
    font-size: 16px !important;
  }
}

.videoblock-wrap {
  padding-bottom: 56.25%;
  height: 0;
  position: relative;

  .innerblock-page & {
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(md) {
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 0;
      padding-bottom: 59.25%;

      iframe {
        border-radius: 0 !important;
      }
    }
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.userimage-block {
  width: 150px;
  height: 150px;
}

.font-72 {
  font-size: 72px;

  @include media-breakpoint-down(md) {
    font-size: 56px;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.bannertext-wrap {
  max-width: 608px;
}

.font-24 {
  font-size: 24px;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.font-28 {
  font-size: 28px;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.font-74 {
  font-size: 74px;

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
}

.mobile-mb-10 {
  @include media-breakpoint-down(md) {
    margin-bottom: 10px !important;
  }
}

.btn.btn-gradientblock {
  background: linear-gradient(266deg, #0B6E4F 2.28%, #08A045 63.64%) !important;
  border: 0 !important;
  font-size: 24px !important;

  @include media-breakpoint-down(md) {
    font-size: 16px !important;
  }

  &:hover {
    background: linear-gradient(266deg, #0B6E4F 2.28%, #08A045 63.64%) !important;
    opacity: 0.8;
  }
}

.hidetop-block {
  .banner-wrapper {
    display: none;
  }
}

.maxw-650 {
  max-width: 650px;
}

.bottomfixed-block {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;

  @include media-breakpoint-down(md) {
    bottom: 30px;
  }
}

.mt-115 {
  @include media-breakpoint-up(md) {
    margin-top: 115px;
  }
}


.mobile-minh53 {
  @include media-breakpoint-down(md) {
    min-height: 53.39px;
  }
}

.mobile-mb-12 {
  @include media-breakpoint-down(md) {
    margin-bottom: 12px !important;
  }
}

div {

  .text-danger {
    color: #F00 !important;
  }
}

.mobile-font-14 {
  @include media-breakpoint-down(md) {
    font-size: 14px !important;
  }
}

.innerblock-page {
  background-color: #000;
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    padding: 16px 16px 16px 0;
  }

  .page-wrapper {

    min-height: calc(100vh - 32px);
    background: #F5F7F9;
    color: $body-bg;
    padding: 0;
    position: relative;
    z-index: 99;

    @include media-breakpoint-up(lg) {
      border-radius: 30px 0px 0px 30px;

    }

    @include media-breakpoint-down(lg) {
      min-height: calc(100vh - 59px);
    }

  }

  .innerpage-header {
    @include media-breakpoint-up(lg) {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      border-radius: 30px 0px 0px 0px;
      background: $white;
    }
  }
}

.backbutton {
  width: 60px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-17 {
  @include border-radius(17px);
}

.shadow-block {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}

.title-header {
  border-bottom: 1px solid rgba($black, 0.2);
}

.title-header-top {
  border-top: 1px solid rgba($black, 0.2);
}

.small-image {
  width: 58px;
  height: 61px;
  border-radius: 8px;
  overflow: hidden;
}

.box-leftblock {
  width: var(--boxLeftWidth);
}

.box-rightblock {
  width: var(--boxRightWidth);
}

.form-inputblock {
  @include border-radius(6px);
  border: 1px solid rgba($black, 0.4);
  min-height: 45px;
  padding: 10px;
  color: rgba($svart, 0.5) !important;

  .innerblock-page & {
    font-weight: 300 !important;

    @include media-breakpoint-down(md) {
      min-height: 32px;
      font-size: 13px;
    }
  }

}

.bg-gradientbox {
  background: linear-gradient(167deg, #191919 1.56%, #7015FF 31.53%, #191919 98.27%);
}

.profile-leftbox {
  width: calc(100% - 90px);
}



.rounded-15 {
  @include border-radius(15px);
}

.h-38px {
  height: 38px;
}

.teacherinfo-box {
  font-weight: 300;

  .link-hover {

    &:hover {
      color: $primary !important;
    }

    &.active {
      color: $primary !important;
    }
  }

  &.disable {

    opacity: 0.3;
  }

}

.border-block {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.btnfont-16 {
  font-size: 16px;
  padding-left: 26px;
  padding-right: 26px;
}

.shadow-selectwrap {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-radius: 20px;
  background: $white;

  .myselect__control {
    box-shadow: none !important;
    border: 0 !important;

    @include media-breakpoint-down(lg) {
      min-height: 21px !important;
    }
  }

  .form-check {
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }

    .form-check-input {
      border: 1px solid var(--Svart, #191919);
      opacity: 0.7;
    }

    .form-check-label {
      font-size: 20px;
      font-weight: 300;

      @include media-breakpoint-down(lg) {
        font-size: 13px;
      }
    }
  }



  .border-block {
    height: 27px;
    width: 1px;
    background: $svart;
    opacity: 0.4;
    display: inline-block;

    @include media-breakpoint-down(lg) {
      height: 20px;
    }
  }

  .myselect__dropdown-indicator {
    padding: 0;
    width: 20px;
  }

  .myselect__placeholder,
  .myselect__option {
    @include media-breakpoint-down(lg) {
      font-size: 13px !important;
    }
  }

  .myselect__single-value {
    color: $svart !important;
    font-size: 20px;

    @include media-breakpoint-down(lg) {
      font-size: 13px !important;
    }
  }


}

.userblock-content {
  flex-grow: 1;
}

.userblock-imag {
  width: 478px;
  height: 416px;
}

.width-47 {
  width: 47px;
  height: 47px;
}

.profiletext-info {
  flex-grow: 1;
}

.profileimage-box {
  box-shadow: 0.47573px 0.47573px 1.90291px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: hidden;
}

.mobile-font-medium {
  @include media-breakpoint-down(lg) {
    font-weight: 500 !important;
  }
}

.videoprogress-block {
  @include media-breakpoint-down(lg) {
    max-width: 100%;

    img {
      width: 100%;
    }
  }
}

.education-title {
  @include media-breakpoint-down(lg) {
    font-size: 16px;
    font-weight: 500 !important;
    margin-bottom: 2px !important;
  }
}

.subtext-block {
  @include media-breakpoint-down(lg) {
    font-weight: 500 !important;
    color: rgba($svart, 0.6) !important;

    span {
      font-weight: 500 !important;
      color: rgba($svart, 0.6) !important;
    }
  }
}

.table-subtext {
  color: rgba($svart, 0.5);
}

.tableborder-block {
  width: 50%;
  display: block;

  &:not(:last-child) {
    border-right: 1px solid rgba($black, 0.2);
  }
}

.boxborder-block {
  &:not(:last-child) {
    border-bottom: 1px solid rgba($black, 0.2);
  }
}

.minw-400px {
  min-width: 400px;

  @include media-breakpoint-down(lg) {
    min-width: 100%;
  }
}

.innerblock-page {
  .btn-primary {
    &:hover {
      color: $primary !important;
      border-color: $primary !important;
    }
  }
}

.btn.btninner-sec {
  &:hover {
    color: $primary !important;
    border-color: $primary !important;
  }
}

.dashboard-progress {
  .progress {
    height: 24px;
    border-radius: 20px;
    background: #D9D9D9;

    @include media-breakpoint-down(lg) {
      height: 13px;
    }
  }

  .progress-bar {
    background: #08A045 !important;
    font-size: 16px;
    font-weight: 300;

    @include media-breakpoint-down(lg) {
      font-size: 9px;
      line-height: 2;
    }
  }
}

.name-tableblock {
  display: block;
  flex-grow: 1;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid rgba($black, 0.2);
  }
}

.width-126 {
  width: 126px;
  flex-shrink: 0;
  display: inline-block;
  text-align: center;
}

.namecell {
  font-size: 20px;
  font-weight: 300;
  color: $svart;

  @include media-breakpoint-down(lg) {
    font-size: 13px;
  }
  &.active {
    color: $primary !important;
  }
}

.btn-font-24 {
  @include media-breakpoint-up(lg) {
    font-size: 24px !important;
  }
}

.btnrounded-20 {
  @include border-radius(20px !important);
}

.blockcell-wrap {
  &:last-child {
    .pb-0 {
      padding-bottom: 10px !important;
    }
  }
}

.closeclass-block {
  .btn-close {
    top: 17px;
    opacity: 0.53;
    padding: 2px;
  }
}

.svgbox {
  @include media-breakpoint-down(lg) {
    svg {
      width: 16px;
    }
  }
}

.plus-btn {
  width: 24px;
  height: 24px;
  font-size: 40.201px;
  font-weight: 300;
  opacity: 0.53;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.formcheck-info {
  .form-check-label {
    font-size: 20px;
    color: #000;
    font-weight: 300;
  }
}

.selectinfo-box {
  .myselect__indicator-separator {
    display: none;
  }

  .myselect__single-value {
    font-weight: 500;
    font-size: 20px;
    color: #191919;
  }
}

.bgbox {
  .form-check-input[type=checkbox] {
    border: 1px solid var(--Svart, #191919);
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.round-block {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba($svart, 0.7);
  color: $svart;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50%;
}
.course-select{
  &.single-select{
    max-width: 200px !important;
    margin-bottom: 0;
  }
  .myselect__control{
    min-height: 54px;
    border-radius: 20px;
    @include media-breakpoint-down(lg) {
      min-height: 34px;
    }
  }
  .myselect__placeholder, .myselect__option{
    font-size: 16px !important;
  }
  .myselect__value-container{
    text-align: center;
  }
  .myselect__indicator-separator{
    width: 0;
  }
}
.type_button {
  justify-content: center;
}
.education-form{
  .form-control{
    // min-height: 60px;
  }
}
.about-us-img{
  height: 540px;
  
  @include media(down, $breakpoint-md) {
    height: 203px;
  }
}
.activate-link {
  &.active {
    color: $white !important;
  }
}
.activate-link-black {
  &.active {
   opacity: 1 !important;
  }
}
.free-color {
  p {
    color: $primary !important;
    opacity: 1 !important;
  }
  
}
.footer_black-black {
  color: $black !important;
 }

 .align-items-lg-normal{
  @include media(up, $breakpoint-lg) {
    align-items: normal !important;
  }
 }