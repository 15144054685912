@font-face {
   font-family: 'icomoon';
   src:  url('../../fonts/icomoon.eot?lxnax7');
   src:  url('../../fonts/icomoon.eot?lxnax7#iefix') format('embedded-opentype'),
     url('../../fonts/icomoon.ttf?lxnax7') format('truetype'),
     url('../../fonts/icomoon.woff?lxnax7') format('woff'),
     url('../../fonts/icomoon.svg?lxnax7#icomoon') format('svg');
   font-weight: normal;
   font-style: normal;
   font-display: block;
 }
 
 [class^="icon-"], [class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: 'icomoon' !important;
   speak: never;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
 
   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 .icon-left-angle-icon:before {
  content: "\e90f";
}
 .icon-close:before {
  content: "\e90e";
}
 .icon-right-angle-icon:before {
  content: "\e90d";
}
 .icon-Bell_pin:before {
  content: "\e903";
}
 .icon-ic-checkout:before {
   content: "\e900";
 }
 .icon-ic-user:before {
   content: "\e901";
 }


.icon-ic-bag:before {
  content: "\e902";
}
.icon-ic-chart:before {
  content: "\e904";
}
.icon-ic-dashboard:before {
  content: "\e905";
}
.icon-ic-edit:before {
  content: "\e906";
}
.icon-ic-logout:before {
  content: "\e907";
}
.icon-ic-play .path1:before {
  content: "\e908";
  opacity: 0.65;
}
.icon-ic-play .path2:before {
  content: "\e909";
  margin-left: -1.0322265625em;
}
.icon-ic-setting:before {
  content: "\e90a";
}
.icon-ic-trash:before {
  content: "\e90b";
}
.icon-ic-View_hide:before {
  content: "\e90c";
}
.icon-book:before {
  content: "\e91f";
}
.icon-books:before {
  content: "\e920";
}
.icon-profile:before {
  content: "\e923";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-insert-template:before {
  content: "\ea72";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-command:before {
  content: "\ea4e";
}

.icon-coin-dollar:before {
  content: "\e93b";
}
.icon-users:before {
  content: "\e972";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-eye:before {
  content: "\e9ce";
}