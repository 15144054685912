.tox .tox-statusbar{
   display: none !important;
}
.tox .tox-toolbar,
.tox .tox-edit-area__iframe{
   background-color: transparent !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header{
   background-color: transparent !important;
   border-bottom: 1px solid rgba($light, 0.25) !important;
   box-shadow: none !important;  
   padding: 10px 0 !important;
}
.tox .tox-toolbar-overlord{
   background-color: transparent !important;
}
.tox .tox-toolbar__primary{
   background-color: transparent !important;
}
.tox-tinymce{
   border: 1px solid #3E3E3E !important;
   border-radius: 0 !important;
}
.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg,
.tox .tox-tbtn svg{
   fill: $white !important;
}

.edit-btn{
   top: 50%;
   transform: translateY(-50%);
   right: 34px;
}
.control-withic{
   .form-control{
      padding-right: 70px;
   }
}
.price-wrapper{
   @include media(up, $breakpoint-sm){
      margin-left: -17px;
      margin-right: -17px;
      > *{
         max-width: 270px;
         margin-left: 17px;
         margin-right: 17px;
      }
   }
   @include media(down, $breakpoint-sm){
      > *{
         flex-grow: 1;
      }
   }
}
.addnew-productpage{
   padding: size(44) 0 size(245);
   @include media(down, $breakpoint-lg){
      padding: size(50) 0 size(100);
   }
}