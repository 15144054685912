.course-block{
   background: #202020;
   border: 1px solid $light-gray;   
}
.course-image{
   width: size(60);
   height: size(60);
}
.course-priceinfo{
   padding: 8px 22px;
   border: 1px solid $light-gray;
   background-color: transparent;
   color: #fff;
   @include border-radius(45px);
   display: inline-block;
   &:not(:last-child){
      margin-right: size(16);
   }
}
.top-title{
   margin-top: calc(1.75rem + 0.375rem);
   @include media(down, $breakpoint-sm){
      margin-top: size(30);
   }
}

.cvc-control{
   width: 80px;
   padding-left: 18px;
   padding-right: 18px;
   border-left: 1px solid rgba($light, 0.2) !important;
}
.date-control{
   padding-left: 18px;
   padding-right: 18px;
   width: 100px;
   border: 0;
}
.cart-cardblock{
   border: 1px solid $light-gray;
   .react-datepicker-wrapper{
      width: 100px;
   }
   .form-control{
      border: 0pc;
   }
}
.cart-rightwrapper{
   .form-control{
      &:focus{
         position: relative;
         z-index: 2;
      }
   }
}
.city-codecontrol{
   padding-left: size(22);
   padding-right: size(22);
   margin-left: -1px;
   width: calc(100% + 1px);
}
.address-blockwrap{
   margin-top: -1px;
}
.pay-btn{
   padding: 10px size(48);
   text-shadow: 0px 4px 4px rgba($black, 0.25);
}
.cart-subleft{
   width: 250px;
   @include media(down, $breakpoint-sm){
      width: 50%;
   }
}
.cart-subright{
   width: calc(100% - 250px);
   @include media(down, $breakpoint-sm){
      width: 50%;
   }
}
.leftblock-products{
   @include media(up, $breakpoint-lg){
      &::after{
         content: '';
         width: 1px;
         height: 100%;
         max-height: 300px;
         top: 8%;
         position: absolute;
         background-color: rgba($light, 0.2);
         right: size(-28);
      }
   }
}