.logintitle{
   margin-bottom: 37px;
   padding: 0 26px 14px;
}
.loginpage{
   .form-control{
      border: 1px solid $white;
      height: 41px;
      border-radius: size(50) !important;
      background-color: transparent;
      @include placeholder{
         color: $white !important;
      }
   }
}

// Starts Css for signup page
.signup-page{
   padding-bottom: size(144);
}
.custom-radio{
   margin: 0 size(-17) size(-10);
   input{
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
      &:checked{
         + .form-check-label{
            background-color: $primary;
            // &:hover, &:focus{
            //    background-color: transparent !important;
            //  }
         }      
      }
   }
   .form-check{
      padding: 0 size(17) size(10);
   }
   .form-check-label{
      font-size: size(20);
      min-width: size(200);
      color: $white;
      padding: size(10);
      text-align: center;
      @include border-radius(25px);
      border: 1px solid $primary;
      background-color: $primary;
      transition: all 0.2s ease;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: $font-regular;
      &:hover{
         background-color: transparent;
      }
   }
}

.congratulation-title{
   margin-bottom: size(30);
   @include media(down, $breakpoint-xl){
      margin-bottom: size(20);
   }
}


.question-control{
   max-width: 1020px;
}

/* Starts Css for Select Course Page */
.plus-minusbtn{
   margin: 0 -10px;
   padding-left: size(27);
   @include media(down, $breakpoint-lg){
      margin: 0 -5px;
   }
   .btn{
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      @include media(down, $breakpoint-lg){
         margin: 0 5px;
         width: size(45);
         height: size(45);
      }
   }
}
.select-coursepage{
   padding: size(30) 0 size(200);
   @include media(down, $breakpoint-lg){
      padding: size(50) 0 size(100);
   }
}

.table-img{
   width: size(50);
   height: size(50);
   display: block;
   img{
      @include border-radius(50%);
   }
}

/* Starts Css for change Password */
.block-wrapper{
   @include media(up, $breakpoint-md){
      max-width: 511px;
   }
}
.changepassword-wrap{
   > .row{
      > [class*=col-]{
         position: relative;
         &:first-child{
            @include media(up, $breakpoint-md){
              
            }
         }
      }
   }
}
/* Ends Css for change Password */

/* Starts Css for Product List Page */
.action-btn{
   width: 30px;
   height: 30px;
   @extend %flex;
   margin: 0 10px;
   &.icon-ic-View_hide{
      font-size: 22px;
   }
   &.icon-ic-edit{
      font-size: 20px;
   }
   &.icon-ic-trash{
      font-size: 24px;
   }
}
.product-listpage{
   padding-bottom: size(64);
}
/* Ends Css for Product List Page */

/* Starts Css for Creators Page */
.file-uploadblock{
   font-size: size(18);
   color: $white;
   font-weight: $font-semibold;
   @include border-radius(10px);
   padding: size(16) size(70) size(16) size(20);
   background-color: $secondaryLight;
   border: 1px solid $light-gray;
   position: relative;
   
   &::after{
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      left: size(68);
      top: 0;
      bottom: 0;
      background: $light-gray;
   }

}
.file-icon{
   width: size(30);
   height: size(30);
   @extend %flex;
   margin-right: size(30);
   
}
/* Ends Css for Creators Page */
/* Starts Css for Create Bussienss Account Page */
.createbussine-account{
   padding: size(97) 0 size(253);
   @include media(down, $breakpoint-lg){
      padding: size(50) 0 size(100);
   }
}
/* Ends Css for Create Bussienss Account Page */

/* Starts Css for Courses Page */
.courses-page, .creators-page{
   padding: size(30) 0 size(150);
   @include media(down, $breakpoint-lg){
      padding: size(50) 0 size(100);
   }
}
/* Ends Css for Courses Page */
.toggle-btn{
   width: 50px;
   position: absolute;
   right: 5px;
   top: 10px;
   box-shadow: none !important;
   outline: 0 !important;
}
.mycoursedetails-page{
   padding: size(50) 0 size(83);
   
   @include media(down, $breakpoint-lg){
      padding: size(50) 0 size(100);
   }
}
.react-datepicker-popper{
   z-index: 5 !important;
}
.form-data .password-input {
   position: relative;
 }
 .password-input {
   position: relative;
   .input-group-text {
     background-color: transparent;
     padding: 0;
     border: none;
   }
 
   i {
     position: absolute;
     right: 13px;
     top: 16px;
     font-size: 19px;
     cursor: pointer;
     color: #FFF;
     z-index: 9;
   }
 }