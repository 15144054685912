.video-block {
  padding-bottom: 53%;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &.video-opencourse .player {
    border-radius: 20px !important;
  }
}

.video-opencourse .player {
  border-radius: 20px !important;

  .vp-center {
    @include media(down, $breakpoint-md) {
      align-items: flex-start;
    }
  }
}
.video-opencourse#videoIframe {
  @include media(down, $breakpoint-md) {
    border-radius: 10px;
  }
}

// Starts Css for Course Manage
.video-wrapper {
  padding: 57% 0 0 0;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.course-detailswrap {
  background-color: $secondary;
}

.course-details {
  padding: size(20) size(20);

  @include media(down, $breakpoint-lg) {
    padding: size(20) size(20);
  }
}

.btn-assign {
  padding: 9px size(30);
  width: 100%;
  text-align: left;
  font-size: size(18);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 30px;
  }

  @include media(down, $breakpoint-lg) {
    padding: 9px size(30);
  }

  @include media(down, $breakpoint-sm) {
    font-size: size(24);
  }

  img {
    margin-right: size(25);
  }
}

.user-img {
  // width: 30%;
  height: size(350);

  @include media(down, $breakpoint-lg) {
    // width: 30%;
    height: size(200);
  }
}

.course-detailswrapper {
  border: 1px solid $light-gray;
}

.course-detailsblock {
  padding: size(30) size(30);

  @include media(down, $breakpoint-lg) {
    padding: size(30) size(20);
  }

  @include media(down, $breakpoint-md) {
    align-items: center;
    justify-content: center;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 10px;
    div {
      margin-left: 0 !important;
    }
  }
}

.small-videoblock {
  padding-bottom: 57%;
}

.teams-block {
  @include border-radius(0);

  .card-header:first-child {
    @include border-radius(0);
  }

  .card-body {
    padding: size(20) size(30);

    @include media(down, $breakpoint-md) {
      padding: size(20) size(20);
    }
  }
}

.completed-mark {
  width: size(94);
  margin-right: size(20);
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 1px;
    height: auto;
    position: absolute;
    right: 0;
    top: size(-15);
    bottom: size(-15);
    background: $light-gray;
  }
}

.completed-wrap {
  border: 1px solid $light-gray;
  @include border-radius(10px);
  padding: size(15) 0 size(15) size(10);
}

.teacherinfo-box {
  > .row {
    > [class*="col-"] {
      &:first-child {
        @include media(up, $breakpoint-md) {
          border-right: 1px solid $light-gray;
        }

        @include media(down, $breakpoint-md) {
          border-bottom: 1px solid $light-gray;
        }
      }
    }
  }
}

.couescount-wrap {
  gap: 15px;

  .teacherinfo-box {
    width: calc(50% - 100px);
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media(down, $breakpoint-lg) {
    gap: 10px;
  }

  @include media(down, $breakpoint-md) {
    width: 100%;
  }
}

.teacherinfo-box tr {
  &:hover {
    box-shadow: -1px 0 1px 1px rgba(112, 21, 255, 0.75),
      inset -1px 0 0 1px rgba(112, 21, 255, 0.75);
    cursor: pointer;
  }
}

.teacherleft-block {
  padding: size(30) size(30);

  @include media(down, $breakpoint-md) {
    padding: size(30) size(20);
  }
}

.teacherright-block {
  padding: size(30) size(30);

  @include media(down, $breakpoint-md) {
    padding: size(30) size(20);
  }
}

.course-timing {
}

.radio-button {
  min-width: size(134);
  height: size(53);
  @extend %flex;
  border: 1px solid $light-gray;
  @include border-radius(12px);
}

.team-text {
  height: 28px;
}

.radio-btnwrap {
  &:not(:last-child) {
    margin-right: size(20);
  }

  input {
    &:checked {
      & + label {
        .radio-button {
          border-color: $white;
        }

        .team-text {
          font-size: size(20);
        }
      }
    }
  }
}

.teamlikes-wrap {
  .cardtext-block {
    padding: size(20) size(30);
    border-bottom: 1px solid $light-gray;

    @include media(down, $breakpoint-md) {
      padding: size(20) size(20);
    }
  }

  .card-body {
    padding: size(30) size(30);

    @include media(down, $breakpoint-md) {
      padding: size(20) size(20);
    }
  }
}

/* Starts css for course Management */
.width-40 {
  width: 40px;
  height: 40px;
  @extend %flex;
  border: 1px solid $light-gray;
  @include border-radius(50%);
}

.border-block {
  .borderbottom-block {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.borderbottom-block {
  border-bottom: 1px solid $light-gray;
}

.playlist-text {
  padding: size(30) size(30);

  @include media(down, $breakpoint-lg) {
    padding: size(30) size(20);
  }
}

.playlist-smallbox {
  padding: size(35) size(30);

  @include media(down, $breakpoint-lg) {
    padding: size(30) size(20);
  }

  .slider-horizontal {
    max-width: 230px;
  }
}

.table {
  .slider-horizontal {
    max-width: 230px;
  }
}

.assigncourse-wrap {
  .form-control {
    @include border-radius(14px);
  }
}

.usermanagement-modal {
  .rangeslider-horizontal {
    height: 15px;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: size(50);
    height: size(50);
  }

  .rangeslider .rangeslider__handle .rangeslider__handle-label {
    font-size: size(16);
  }
}

.certificate-img {
  width: calc(100% / 3);
  height: 137px;
  padding: 0 10px;
}

.certificate-imgwrap {
  margin: 0 -10px;
}

.datepicker-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: size(20);
}

.datepicker-block {
  .form-control {
    padding-left: 60px;
  }
}
.feature-card-slider {
  padding: 20px;
}

.future-slider-list .categories-slider {
  @include media(down, $breakpoint-md) {
    display: none;
  }
}
.width-350 {
  @include media(down, $breakpoint-xl) {
    width: 70% !important;
  }
  @include media(down, $breakpoint-md) {
    width: 100% !important;
  }
}

.btn.btn-outline-light {
  padding: 10px 26px;
  transition: all .4s ease;

  &:hover {
    color: #7015ff !important;
    // box-shadow: #fff 0 0px 0px 40px inset;
    border-color: transparent;
    background-color: $white;
  }
}

.thumbnail-image {
  width: 100%; /* Set the width and height as needed */
  height: auto;
}


.kurser-pagination{
    .pagination{
      border-top: 1px solid rgb(0 0 0 / 25%);
      padding-top: 20px;
      margin: 20px -24px 0;
      @include media(down, $breakpoint-xl) {
        margin: 21px -21px 0;
        padding-top: 21px;
      }
      @include media(down, $breakpoint-sm) {
        margin: 14px -14px 0;
        padding-top: 14px;
      }
      @include media(down, $breakpoint-xs) {
        margin: 12px -12px 0;
        padding-top: 12px;
      }
      .page-link{
        background-color: transparent;
        color: gray;
        border: 0;
        &:hover{
          color: $primary !important;
        }
        &:focus{
          box-shadow: none;
        }
      }
      .page-item{
        &.active{
          .page-link{
           color: $primary;
          }
        }
      }
    }
}
.avsnitt-btn-mob{
  border-top: 1px solid rgb(0 0 0 / 25%);
  margin: 12px -12px 0;
  padding-top: 12px;
}
.webinar-video-frame{
  position: relative;
}
.webinar-video-frame iframe, .webinar-video-frame object, .webinar-video-frame embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin:0px;
}
.webinar-video-frame .ratio {
  display:block;
  width:100%;
  height:auto;
}
.webinar-video-frame iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}