// MEDIA BREAKPOINTS
@mixin media($breakpoint, $point) {
  @if $breakpoint == down {
    @media (max-width: $point - .1) { @content; }
  }
  @else if $breakpoint == up {
    @media (min-width: $point) { @content; }
  }
}




@mixin checked-square(){
  content: "";
  position: absolute; 
  width: 6px;
  height: 15px;
  border: solid $white;
  border-width: 0 size(2) size(2) 0;
  background-color: #000;
  @include css3-prefix(transform, rotate(45deg));
  display: none;
}
// CALC FUNCTION RULE
@mixin calc($property, $expression) { 
  #{$property}: calc(#{$expression});
}
// Usage Calc Function demo 
// div {
//    @include calc( width, '100% - 2px');
// }

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}
// Usage Prefix 
// div {
//    @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// }
  
// POSITION 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// TEXT ELLIPSIS
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Button
// @mixin button-size($btn-padding-y, $btn-padding-x, $font-size-base, $line-height-base, $box-shadow) {
//   padding: $btn-padding-y $btn-padding-x;
//   font-size: $font-size-base;
//   line-height:$line-height-base;
//   box-shadow: $box-shadow;
// }


// PLACEHOLDER RULE
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}



// transition
@mixin transition-0-10s{
  transition: all 0.10s linear;
  -webkit-transition: all 0.10s linear;
  -moz-transition: all 0.10s linear;
  -ms-transition: all 0.10s linear;
  -o-transition: all 0.10s linear;
}
@mixin transition-0-15s{
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}
@mixin transition-0-2s{
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
@mixin transition-0-3s{
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear; 
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
@mixin transition-0-5s{
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

%flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

