.pricing-list{
	opacity: 0.8;
	li{
		display: flex;
		align-items: center;
		&:before{
			content:'';
			display: block;
			width: 15px;
			height: 15px;
			background: url('./../../../../public/assets/images/SVG/check-white.svg') no-repeat center !important;
			background-size: contain !important;
			flex-shrink: 0;
			margin-right: 8px;
		}
	}
}
// .pricing-box{
	
// }
.rightmarkic{
	a{
	width: 40px;
	height:40px;
	img{
	width: 20px;
	}
	}
}
.skill-infobx{
	
@include media(up, $breakpoint-md){
	min-height: 160px;
	width: 186px;
	justify-content: center;
	}
	@include media(down, $breakpoint-md){
	width: 100%;
	min-height: 80px;
	}
}
.rounded-20{
	@include border-radius(20px);
}
.pb-sm-50{
	@include media(down, $breakpoint-md){
	padding-bottom: 50px !important;
	}
}