.homepage {
  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 0 !important;
    z-index: 101;

    @include media-breakpoint-down(md) {
      z-index: 1111;
    }
  }
}
.modal-open{
  .homepage {
    header {
      @include media(down, $breakpoint-md) {
        background: $dark;
      }
    }
  }
}

.font-large {
  font-size: 60px;

  @include media(down, $breakpoint-lg) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media(down, $breakpoint-md) {
    font-size: 28px;
    line-height: 38px;
  }

  @include media(down, $breakpoint-sm) {
    font-size: 20px;
    line-height: 22px;
  }
}

iframe {
  // width: 570px;
  // max-width: 100%;
  // height: 570px;
  // object-position: center;
  // @include media(down, $breakpoint-md){
  //   height:100vw;
  //   width: 100%;
  // }
}

.iframe-video {
  @include media(down, $breakpoint-md) {
    text-align: center;
  }
}

%boarding-image {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 140px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: url("../../../../public/assets/images/Desktop.webp") no-repeat 100% 100%;
  background-size: contain;
}

.banner-blockwrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    min-height: auto;
  }
}
.home-banner{
  @include media-breakpoint-down(sm) {
    .btn{
    &:not(:last-child){
      margin-top: 13px;
    }
    }
  }
}
.banner-wrapper {
  min-height: 500px;
  // padding: 410px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-grow: 1;
  // min-height: 719px;
  overflow: hidden;

  // public/assets/images/Lilabakgrund.png
  // background: url('../../../../public/assets/images/Lila\ bakgrund.png') no-repeat;
  // background-size: cover;
  &.bg-wrap {
    background: url("../../../../public/assets/images/Lila\ bakgrund.png") no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
      height: 250px !important;
      min-height: auto !important;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 44%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.13%, #000 98.17%);
    }

  }

  &.blg-wraper {
    min-height: 80vh;
  }

  &.banner-sec {
    &::after {
      right: 0px;
      top: 0px;
      bottom: 0px;
      left: auto;
      background: url("../../../../public/assets/images/Desktop.webp") no-repeat 100% 100%;
      background-size: cover;
      width: 60%;
      @extend %boarding-image;

      @include media-breakpoint-down(md) {
        left: auto;
        width: 60%;
        background-size: contain;
        background: url("../../../../public/assets/images/Mobile_benner.png") no-repeat 100% 100%;
        background-size: cover;
      }

      @include media-breakpoint-down(sm) {
        // left: 125px;
        top: 12px;
      }
    }
  }
  
  h1{
    font-weight: $font-semibold;
  }

  @include media(down, $breakpoint-lg) {
    // height: 48vw;
    // padding: 160px 0 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media(down, $breakpoint-md) {
    min-height: 90vh !important;
  }

  @include media(down, $breakpoint-sm) {
    // padding: 90px 0 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  h1 {
    @include media(up, $breakpoint-xxxl) {
      font-size: 72px;
      line-height: 72px !important;
    }

    @include media(down, $breakpoint-xxxl) {
      line-height: 1 !important;
    }
  }

  .banner-text {
    @include media(down, $breakpoint-md) {
      text-align: center;
    }
  }
}
.webinar-banner-block{
  &.banner-wrapper{
    &.bg-wrap{
      @include media-breakpoint-down(md) {
        height: auto !important;
      }
    }
  }
}
.webinar-details-imgbox{
  height: 337px;
  @include media-breakpoint-down(md) {
    height: 216px;
  }
  @include media(down, $breakpoint-sm) {
    height: 216px;
    margin: 0 -20px;
    border-radius: 0 !important;
  }
  .rounded-20{
    @include media(down, $breakpoint-sm) {
      border-radius: 0 !important;
    }
  }
  .font-64{
    @include media(down, $breakpoint-xl) {
      font-size: 44px !important;
    }
    @include media(down, $breakpoint-sm) {
      font-size: 36px !important;
    }
  }
}

.banner-blocksection {
  background: url("../../../../public/assets/images/banner-img2.jpg") no-repeat;
  background-size: cover;
}

.planner-sec {
  margin-top: -56px;
  z-index: 1;
}

.planner-block {
  .btn {
    padding: size(15) size(22);

    @include media(down, $breakpoint-sm) {
      padding: 15px size(16);

      img {
        max-width: 26px;
      }
    }
  }
}

.btn-img {
  max-width: 25px;
  margin-top: -1px;
}

.page-titletext {
  padding-bottom: size(20);
  margin-bottom: size(40);
}

.topics-wrapper {
  margin: 0 -13px;

  li {
    width: 20%;
    padding: size(15) size(13);
    font-weight: $font-semibold;
    color: $white;

    @include media(down, $breakpoint-lg) {
      width: 25%;
    }

    @include media(down, $breakpoint-md) {
      width: 33.33%;
    }

    @include media(down, $breakpoint-sm) {
      width: 50%;
    }

    a {
      border: 1px solid $dark-shade1;
      color: $white;
      text-align: center;
      @extend %flex;
      min-height: 63px;
      transition: all 0.2s ease;
      font-weight: $font-medium;
      font-size: size(15);
      letter-spacing: 0.2px;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $black;
      }
    }
  }
}

.popular-topicwrap {
  &::after {
    content: "";
    display: block;
    height: 2px;
    background-color: rgba($light, 0.5);
    max-width: 463px;
    width: 60%;
    @include position(absolute, auto, auto, 0, 50%);
    transform: translateX(-50%);
  }
}

.testimonial-wrapper {
  padding: size(42) 0 size(155);
}

.testimonial-img {
  width: size(166);
  height: size(166);
  border: size(10) solid #2c2e2e;
}

.our-clientswrap {
  padding-bottom: size(80);

  .form-control {
    border: 1px solid #c4c4c4;

    &:focus {
      border-color: $primary;
    }

    font-size: $font-size-base;

    @include placeholder {
      color: $white !important;
    }
  }
}

.logo-block {
  border: 1px solid #c4c4c4;
  height: size(90);
}

.banner-text {
  h1 {
    max-width: 1100px;
    margin: 0 auto;
  }
}

// .bottom-text{
//    padding: 40px 65px;
//    @include media(down, $breakpoint-lg){
//       padding: 20px 35px;
//    }
//    @include media(down, $breakpoint-sm){
//       padding: 0;
//    }
// }
.contactus-form {
  &::after {
    content: "";
    width: 1px;
    height: 100%;
    max-height: 180px;
    background: rgba($light, 0.4);
    position: absolute;
    right: size(-50);
    position: absolute;
    top: 42%;
    transform: translateY(-50%);

    @include media(down, $breakpoint-xl) {
      right: size(-15);
    }
  }
}

.border-bottom-none {
  border-bottom: 0 !important;
}

.getstart-block {
  transition: all 0.15s ease;
  @include border-radius(32px);
  height: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  border: 2px solid transparent;

  .innerblock-page & {
    border: 0;
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    border-radius: 20px;
  }

  // &.active, &:hover{
  //    border: 2px solid #7015FF !important;
  // }
  img {
    height: 412px;

    .innerblock-page & {
      height: 340px;

      @include media-breakpoint-down(md) {
        height: 197px;
      }
    }
  }

  .img-content {
    z-index: 2;
    padding: 26px 26px !important;

    @include media-breakpoint-down(md) {
      padding: 20px 16px !important;

      text-align: center;

      .innerblock-page & {
        text-align: left;
        padding-bottom: 16px !important;
      }
    }
  }

  &:hover {
     opacity: 0.96;
    transition: 0.3s;
      cursor: pointer;
    //  box-shadow: -1px 0 1px 1px rgba(112, 21, 255, 0.75), inset -1px 0 0 1px rgba(112, 21, 255, 0.75);
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 44%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 40.03%);
    content: "";

    .innerblock-page & {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 58.63%, #000 91.27%);
      top: 0;
      background-repeat: no-repeat;
      background-position: bottom;
      height: auto;
    }
  }

  .product-card-info {
    top: size(24);
    right: size(24);
    z-index: 1;
    padding: size(8) size(16);
    font-size: size(11);
    background: rgba(17, 17, 17, 0.8);
    border-radius: size(16);
  }

  .product-card-content {
    padding: 20px 26px 34px;
    z-index: 1;
    color: $white;
    position: relative;
  }
}
.getstart-block-new{
  &.getstart-block{
    transition: all 0.5s ease;
    &:hover{
      box-shadow: none ;
      // border-color: $primary;
    }
    &::after{
      @include media(down, $breakpoint-md) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 82.03%);
      }
    }
  }
  .get-started-img{
    width: 100%;
    height: 320px;
    @include media(down, $breakpoint-sm) {
      height: 260px;
    }
    &:hover {
      opacity: 0.8;
      //  border: 1px solid #7015ff;
    }
  }
  &.feature-box{
    .feature-img{
      border-radius: 20px 20px 0 0;
    }
    .img-content {
      position: relative;
      padding: 20px !important;
      @include media(down, $breakpoint-lg) {
        position: absolute;
      }
    }
  }
}
// .course-details-blocks{
//   .getstart-block{
//     &:hover{
//       box-shadow: none;
//       &:after{
//         background: rgb(0 0 0 / 40%);
//         transition: 0.2s all;
//       }
//     }
//   }

// }
.banner-image {
  border: 1px solid $white;
}

.custome-height {
  @include media(up, $breakpoint-md) {
    height: auto;
    width: auto;
  }

  @include media(up, $breakpoint-xl) {
    height: 360px;
    width: 590px;
  }

  @include media(up, $breakpoint-xxxl) {
    height: 450px;
    width: 765px;
  }
}

.portfolio-image {
  border-radius: 20px;
  overflow: hidden;
  // width: 320px;
  // min-height: 320px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.portfolio-leftblock {
  @include media(down, $breakpoint-md) {
    text-align: center;
  }

  color: $secondary;

  p {
    max-width: 500px;
  }
}

.right-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.input-group {
  &.ct-group {
    .form-control {
      border: 1px solid #6418ff;
      background-color: $white;
      border-radius: 30px;
      color: $secondary;

      &::placeholder {
        color: $secondary !important;
      }
    }

    .input-group-text {
      background-color: #6418ff;
      border: 1px solid #6418ff;
      border-radius: 30px;

      &:hover {
        border: 1px solid #7015ff;
      }
    }
  }
}

.wrap-round {
  border: 2px solid $white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  flex-direction: column;
  position: relative;
  margin-left: 42px;

  @include media(down, $breakpoint-sm) {
    width: 150px;
    height: 150px;
    display: none;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
  }
}

.wrap-block {
  @include media(down, $breakpoint-md) {
    text-align: center;

    ul {
      list-style: none;
    }
  }

  p {
    max-width: 541px;

    @include media(up, $breakpoint-xxxl) {
      margin-bottom: 1rem !important;
    }

    @include media(down, $breakpoint-md) {
      margin: 0 auto;
    }
  }

  &.port-wrap {
    @include media(down, $breakpoint-md) {
      text-align: center;
    }
  }
}

.testimonial-content {
  p {
    max-width: 500px;
  }
}

.testimonial-image {
  display: flex;
  justify-content: flex-end;
}

.testimonial-blockimg {
  max-height: 300px;
  display: block;

  img {
    width: 100%;
    max-height: inherit;
    object-fit: cover;
    object-position: center;
  }
}

.testimonial-slider {
  @include media(down, $breakpoint-sm) {
    padding-bottom: 70px;
  }

  .slick-slider {
    .slick-next {
      border: 1px solid #fff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: none !important;
      bottom: auto;
      top: 170px;
      right: -50px;
      left: auto;

      @include media(down, $breakpoint-lg) {
        top: 130px;
        right: 0;
        left: auto;
        margin-left: 0;
      }

      @include media(down, $breakpoint-sm) {
        top: 170px;
      }

      &::after {
        content: "\e90d";
        font-family: "icomoon";
        position: absolute;
        top: 0;
        left: 0;
        top: 26px;
        left: 18px;
        font-size: 24px;
        color: #fff;
      }
    }

    .slick-prev {
      border: 1px solid #fff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: none !important;
      bottom: auto;
      top: 170px;
      left: 0px;
      right: auto;

      @include media(down, $breakpoint-lg) {
        margin-left: 0;
        z-index: 9;
        left: 30px;
        top: 130px;
      }

      @include media(down, $breakpoint-sm) {
        top: 170px;
      }

      &::after {
        content: "\e90f";
        font-family: "icomoon";
        position: absolute;
        top: 0;
        left: 0;
        top: 26px;
        left: 15px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}

.slick-dots {
  bottom: -60px;

  li {
    &.slick-active {
      button {
        &::before {
          background: $white;
          opacity: 1;
        }
      }
    }

    button {
      &::before {
        content: "-";
        width: 15px;
        height: 15px;
        background: transparent;
        border: 1px solid $white;
        border-radius: 100%;
      }
    }
  }
}

.featuresslider .slick-dots {
  bottom: -38px;

  li {
    &.slick-active {
      button {
        &::before {
          background: $black;
          opacity: 1;
        }
      }
    }

    button {
      &::before {
        content: "-";
        width: 15px;
        height: 15px;
        background: transparent;
        border: 1px solid $black;
        border-radius: 100%;
      }
    }
  }
}

.pricingSlider {
  .padding-15 {
    padding: 0 !important;
  }
  .slick-list {
    .slick-track {
      // margin-right: 30px;
      display: flex;
    }

    .slick-slide {
      height: inherit !important;
      margin: 0 8px;

      >div {
        height: 100%;
      }
    }
  }
}


.pricingSlider .slick-dots {
  bottom: -30px;

  @include media-breakpoint-down(md) {
    bottom: -60px;
  }

  li {
    &.slick-active {
      button {
        &::before {
          background: $white;
          opacity: 1;
        }
      }
    }

    button {
      &::before {
        content: "-";
        width: 15px;
        height: 15px;
        background: transparent;
        border: 1px solid $white;
        border-radius: 100%;
      }
    }
  }
}

.contactus-form {
  &::after {
    width: 0;
  }
}

.homepage {
  position: relative;
  overflow-x: hidden;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
  }
}

.getstarted-wrap {
  @include media(down, $breakpoint-sm) {
    text-align: center;
  }
}

.ct-group {
  @include media(down, $breakpoint-sm) {
    justify-content: center;
  }
}

.wrap-round {
  @include media(down, $breakpoint-sm) {
    text-align: center;
    margin: 0 auto;
  }

  h2 {
    @include media(up, $breakpoint-xxxl) {
      font-size: 92px;
      line-height: 98px;
    }
  }
}

.wrap-block {
  h2 {
    @include media(up, $breakpoint-xxxl) {
      font-size: 82px;
      line-height: 88px;
    }
  }

  h3 {
    @include media(up, $breakpoint-xxxl) {
      font-size: 35px;
      line-height: 42px;
    }
  }
}

.homeservice-block {
  padding-bottom: 35px;

  .slick-list,
  [class*="col-"] {
    margin-top: -34px;
    position: relative;

    @include media-breakpoint-down(lg) {
      margin-top: -30px;
    }
  }

  .slick-list {
    margin-left: -40px;
    margin-right: -40px;

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        >div {
          height: auto;

          .box-sec {
            height: 100%;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .box-sec {
    padding: 0px 40px;

    @include media-breakpoint-down(lg) {
      padding: 0 15px;
    }
  }
}

.maxw-443 {
  max-width: 449px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.maxw-40 {
  max-width: 40px;
}

.feature-slide {
  .slick-list {
    margin: 0 -5px;
  }

  .slick-arrow {
    bottom: -96px;
  }

  .slick-slider {
    .slick-next {}

    .slick-slide {
      padding: 10px 5px;
    }
  }

  .slick-list {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 364px;
      top: 10px;
      bottom: 9px;
      right: 0;
      background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}
.feature-box-new {
  &:hover {
    opacity: 0.8;
    //  border: 1px solid #7015ff;
  }
}

.feature-box {
  // max-width: 220px;
  // width: 100%;
  position: relative;
 
  .feature-slide & {
    // border: 2px solid transparent !important;
    width: 240px;
    min-height: 412px;
    border-radius: 20px;
    overflow: hidden;

    // @include media-breakpoint-down(lg){
    //   width: 160px;
    // }
    @include media-breakpoint-down(md) {
      width: 143px;
      min-height: 247px;
    }

    &:hover{
     //  border: 2px solid #7015FF !important;
       opacity: 0.8;
    }
  }


  .feature-img {
    border-radius: 20px;
    overflow: hidden;
    height: 412px;

    @include media-breakpoint-down(md) {
      height: 247px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .img-header {
    .btn-img {
      background: $success;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 5px;
      min-width: 75px;
      margin-left: -10px;
      margin-top: -6px;
    }
  }

  .img-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    height: 100%;
    padding: 20px;
    width: 100%;

    .img-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .overlay-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    display: block;
    background: linear-gradient(to bottom, transparent 0%, black 80%);
    border-radius: 0 0 20px 20px;
  }

  .bottom-text {
    @include media-breakpoint-down(md) {
      width: 100%;

      .innerblock-page & {
        text-align: left !important;

        h5 {
          font-size: 16px;
          text-align: left;
        }

        p {
          font-size: 14px !important;
          text-align: left;
          font-weight: 500 !important;
          display: block;
        }
      }
    }

    h3 {
      @include media(down, $breakpoint-sm) {
        font-size: 16px;
      }
    }

    .text-box {
      padding: 10px;
      border: 1px solid $white;

      @include media(down, $breakpoint-sm) {
        padding: 5px;
      }

      p {
        font-size: 14px;
        margin-bottom: 10px;

        @include media(down, $breakpoint-sm) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }

      span {
        font-size: 12px;
      }
    }

    .align-center {
      align-items: center;
    }
  }
}

.showblue .slick-slider .slick-next {
  background: linear-gradient(90deg,
      transparent 17%,
      #130b20 62%,
      #150d25 84%) !important;
}

.vp-center {
  align-items: baseline !important;
}

.edit-border {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.custom-p {
  max-width: 100% !important;
}

.mb-btns {
  display: none !important;

  @include media(down, $breakpoint-sm) {
    display: flex !important;
  }
}

.web-btns {
  display: flex !important;

  @include media(down, $breakpoint-sm) {
    display: none !important;
  }
}

.web-btns1 {
  display: inline-block !important;

  @include media(down, $breakpoint-lg) {
    display: none !important;
  }
}

.web-headinng {
  justify-content: center;

  @include media(down, $breakpoint-sm) {
    display: none !important;
  }
}

.mobile_heading {
  display: none !important;
  justify-content: center;

  @include media(down, $breakpoint-sm) {
    display: flex !important;
  }
}

.font-size-35 {
  font-size: 35px;
}

.font-size-35-A {
  @include media(down, $breakpoint-sm) {
    font-size: 35px;
  }
}

.web_image {
  display: flex !important;

  @include media(down, $breakpoint-sm) {
    display: none !important;
  }
}

.mobile_image {
  display: none !important;

  @include media(down, $breakpoint-sm) {
    display: flex !important;
  }
}

.custome_ul {
  list-style: disc !important;
  list-style-position: inside !important;
}

.custome-a {
  border: 1px solid #7015ff !important;
}

.black-friday {
  @include media(down, $breakpoint-sm) {
    min-height: 100vh !important;
  }
}


.home-banner {
  min-height: 770px;

  .banner-content-area {
    max-width: 725px;
    margin: 0 auto;
  
  }

  &.banner-wrapper.bg-wrap {
    @include media(down, $breakpoint-md) {
      height: auto !important;
      min-height: 655px !important;
    }
  }

  .see-more {
    position: absolute;
    bottom: 61px;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.home-banner-contact {
  min-height: 500px !important;
  &.banner-wrapper{
    align-items: flex-end;
    padding-bottom: 100px;
    &.bg-wrap{
      @include media(down, $breakpoint-md) {
        align-items: center;
        height: auto !important;
        min-height: 364px !important;
        padding: 67px 0 0;
      }
    }
  }
}
.icon-bottom-angle {
  transform: rotate(270deg);
  display: inline-block;
}

.content-info-blocks {
  padding: 40px 0 0;
  margin-bottom: 150px;
  @include media(down, $breakpoint-sm) {
    margin-bottom: 100px;
  }
}
.team-content-block{
  .mockup-label{
    border: 1px solid #FFF;
    border-radius: 25px;
    padding: 8px 25px;
  }
  .form-select:disabled,.form-control:disabled{
    background-color: transparent;
  }
  .form-control{
    border-radius: 6px;
  }
}
.dark-card {
  background: $darkheader-bg;
  padding: 32px;
  border-radius: 20px;
  border: 1px solid rgba($white, 0.2);

  @include media(down, $breakpoint-xl) {
    padding: 20px;
  }

  // p {
  //   color: rgba($white, 0.6);
  // }
}

.text-lightgray {
  color: rgba($white, 0.6);
}

.img-h-237 {
  width: 100%;
  height: 237px;

  @include media(down, $breakpoint-sm) {
    height: 160px;
  }
}
.mobile-order-1{
  @include media(down, $breakpoint-lg) {
    order: 1;
  }
}

.img-h-204 {
  width: 100%;
  height: 204px;

  @include media(down, $breakpoint-sm) {
    height: 160px;
  }
}

.img-h-160 {
  width: 100%;
  height: 160px;
}

.center-fit {
  object-position: center;
}

.wrapper-main {
  @media screen and (max-width: 1500px) and (min-width:1399px) {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
}

.webinar-section-text{
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
  ul{
    li{
      margin-bottom: 0;
    }
  }
}


// New home page sections 
.PlannigSection{
  margin-bottom: 135px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;
  }
  &-titlewrap{
    margin:70px 0 160px;
    @include media-breakpoint-down(sm) {
      margin: 60px 0 64px;
    }
  }
  &-imgwrap{
    max-width: 575px;
    img{
      border-radius: 14px;
      @include media-breakpoint-down(sm) {
        border-radius: 8px;
      }
    }
  }
  &-form{
    display: flex;
    gap: 14px;
    margin-top: 21px;
    .form-control{
      background: #191B23;
      border-radius: 8px;
      border: none;
      min-height: 44px;
      height: 44px;
      @include media-breakpoint-down(sm) {
        min-height: 30px;
        height: 30px;
        font-size: 10px;
        border-radius: 4px;
      }
    }
    .btn-primary{
      flex-shrink: 0;
      text-align: center;
      background: #2572ED !important;
      color: $white !important;
      border-radius: 8px;
      border: none;
      font-size: 14px;
      text-shadow: none;
      font-weight: 500;
      padding: 8px 20px;
      min-height: 44px;
      height: 44px;
      @include media-breakpoint-down(sm) {
        min-height: 30px;
        height: 30px;
        font-size: 10px;
        border-radius: 4px;
      }

      &:hover{
        background: #2572ED !important;
        color: $white !important;
      }
    }
  }
}
.ProduktionSection{
  margin-bottom: 126px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;
  }
  &-imgwrap{
    max-width: 575px;
    margin: 0 auto;
    img{
      border-radius: 14px;
      @include media-breakpoint-down(sm) {
        border-radius: 8px;
      }
    }
  }
}
.followupSection{
  margin-bottom: 123px;
 @include media-breakpoint-down(sm) {
    margin-bottom: 80px;
  }
}
.InterestingSection{
  margin-bottom: 100px;
}
.Booky-slider{
  position: relative;
  @include media-breakpoint-down(sm) {
    padding-bottom: 80px;
  }
  &::after{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    // background: linear-gradient(180deg, rgba(21, 19, 19, 0) 0%, #000000 61.31%);
    // z-index: 1;
    background: linear-gradient(180deg, rgb(21 19 19 / 0%) 0%, #000000 76.31%);
  }
  &-itembox{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 285px;
      height: 400px;
      z-index: 0;
      background: url("../../../../public/assets/images/frame-1.png") center  no-repeat;
      background-size: contain;

      @include media-breakpoint-down(sm) {
        left: -12px;
        width: 130px;
        height: 250px;
        background-size: cover;
      }
    }
    &::after{
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 285px;
      height: 400px;
      z-index: 0;
      background: url("../../../../public/assets/images/frame-2.png") center  no-repeat;
      background-size: contain;

      @include media-breakpoint-down(sm) {
        right: -12px;
        width: 130px;
        height: 250px;
        background-size: cover;
      }
    }
  }
  &-item{
    width: 100%;
  }
  &-bootom-section{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      height: 55%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100.31%);
      z-index: -1;
    }
  }
  .slick-slider{
    z-index: 1;
    position: relative;
  }
  &-logo{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    display: block;
  }
  &-text{
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    margin: 0 auto 20px;
    padding: 0 200px;
      @include media(down, $breakpoint-sm) {
        font-size: 13px;
        padding: 0 45px;
      }
  }
  &-profile{
    img{
      @include media(down, $breakpoint-sm) {
        max-width: 45px;
      }
    }
  }
  &-profileinfo-text1{
    font-size: 18px;
    font-weight: 400;
    @include media(down, $breakpoint-sm) {
      font-size: 13px;
    }
  }
  &-profileinfo-text2{
    font-size: 18px;
    font-weight: 400;
    color: rgb(255 255 255 / 70%);
    @include media(down, $breakpoint-sm) {
      font-size: 13px;
    }
  }
}

// Edu library
.top-index{
  z-index: 1;
  position: relative;
}
.Edu-library{
  padding: 150px 0;
  @include media(down, $breakpoint-sm) {
    padding: 80px 0;
  }
  &-title{
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 8px;
    @include media(down, $breakpoint-sm) {
      font-size: 20px;
    }
  }
  &-subtitle{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    @include media(down, $breakpoint-sm) {
      font-size: 16px;
    }
  }
  &-maintext{
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    @include media(down, $breakpoint-sm) {
      font-size: 13px;
    }
  }
  .slick-slider{
    margin-top: 44px;
  }
  .slick-slide{
    width: 260px !important;
    min-width: 260px;
    height: 260px;
    margin: 0 18px;
    > div{
      height: 100%;
    }
  }
}
.Edu-librarybox{
  width: 100%;
  height: 100%;
  position: relative;
  &::before{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100px;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10.5%, #000000 75.5%);
  }
  // &:after{
  //   background: rgba(112, 21, 255, 0.4);
  //   filter: blur(83.88px);  
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   top: 0;
  // }
  &-mainimg{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
   
  }
  &-logo{
    position: absolute;
    right: 16px;
    top: 16px;
  }
  &-content{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px 16px;
    h4{
      font-size: 23px;
      font-weight: 300;
      margin-bottom: 3px;
      @include media(down, $breakpoint-xxxl) {
        font-size: 18px;
      }
    }
    p{
      margin: 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.contact-bg{
  position: relative;
  &::after{
    content: " " ;
    background: url("../../../../public/assets/images/contact-bg.png") no-repeat ;
    background-size: cover;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .accordion-wrap{
    margin-top: 200px;
    @include media(down, $breakpoint-md) {
      margin-top: 100px;
    }
  }
  .footer{
    padding-top: 80px !important;
    margin-top: 150px !important;
  }
  .Booky-slider{
    @include media(down, $breakpoint-md) {
      padding: 0;
    }
  }
}
.full-bg-black{
  background: #000000;
  .banner-wrapper.bg-wrap::after{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.13%, #000000 98.17%);
  }
}
.homepage-bg{
  position: relative;
  &::after{
    content: " " ;
    background: url("../../../../public/assets/images/home-bg-vectornew.png") no-repeat ;
    background-size: cover;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  .book-section{
    padding: 120px 0;
    @include media(down, $breakpoint-sm) {
      padding: 80px 0;
    }
  }
  .footer  {
    background: #000000;
    margin: 0;
  }
  .home-banner {
    .banner-content-area {
      @include media(down, $breakpoint-md) {
        max-width: 308px !important;
      }
    }
  }
}
.calendar-iframe{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  // max-height: 500px;
  padding: 31px 0 110px;
  .video-wrapper{
    background-color: transparent;
    position: relative;
    width: 100%;
    @include media(down, $breakpoint-sm) {
          min-height: 500px;
          }
  }
}
// .contact-banner{
//   .banner-content-area{
//     padding: 215px 0 100px;
//     @include media(down, $breakpoint-sm) {
//       padding: 140px 0 118px;
//     }
//   }
// }
.text-subtle {
  display: none !important;
}

.edu-custom-slider{
    --gap: 36px;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
    display: flex;
    gap: var(--gap);
    animation: slidermove 50s linear infinite;
    margin-top: 40px;

    &:hover {
      animation-play-state: paused;
    }
    .Edu-librarybox {
      width: 260px;
      height: 260px;
      position: relative;
      min-width: 260px;
    }
}

@keyframes slidermove {
  // from {top: 0px;}
  // to {top: 200px;}
  0% {
    transform: translateX(var(--scroll-start));
  }
  
  100% {
    transform: translateX(var(--scroll-end));
  }
}

