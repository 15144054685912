html {
   display: flex;
   min-height: 100%;

   @include media(down, $breakpoint-xl) {
      font-size: 14px;
   }

   @include media(down, $breakpoint-sm) {
      font-size: 12px;
   }
}

.margin-b-16 {
   margin-bottom: 16px;
}

.w-lg-100{
   @include media(up, $breakpoint-lg) {
      width: 100%;
   }
}
.w-md-100{
   @include media(up, $breakpoint-md) {
      width: 100%;
   }
}

.link-hover {
   transition: color 0.15s ease;
   cursor: pointer;

   &:hover {
      color: $primary !important;
   }
}
.link-hover-primary {
   transition: color 0.15s ease;
   cursor: pointer;
   &:hover {
      span {
      color: $primary !important;
      }
      .round-block {
         border: 2px solid $primary !important;
      }
   }
}

.link-hover-white {
   cursor: pointer;

   &:hover {
      color: $white !important;
   }
}

.border-color-on-hover {
   &:hover {
      transition: 0.3s;
      cursor: pointer;
      box-shadow: -1px 0 1px 1px rgba(112, 21, 255, 0.75), inset -1px 0 0 1px rgba(112, 21, 255, 0.75);
   }
}

.container-fluid {
   padding-left: 15px;
   padding-right: 15px;

   @include media-breakpoint-down(lg) {
      padding-left: 20px;
      padding-right: 20px;
   }
}

body {
   display: flex;
   min-height: 100%;
   flex-grow: 1;
   width: 100%;
   scroll-behavior: smooth;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   // background: linear-gradient(269deg, #000 0%, #191919 66.67%);
   background: #000000;
   font-size: 16px;
   line-height: 1.3;
   overflow-x: hidden;
}

#root {
   width: 100%;

   .App {
      min-height: 100%;
      display: flex;
      flex-direction: column;
   }
}

img {
   width: auto;
   height: auto;
   max-width: 100%;
   max-height: 100%;
}

.img-fit {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: top;
}

.page-wrapper {
   padding: 38px 0;
}

.flex {
   @extend %flex;
}

.container-fluid {
   max-width: 1260px;
}

// General Class
.font-12 {
   font-size: 12px;
}

.font-20 {
   font-size: 20px !important;
}

.font-22 {
   font-size: 22px;

   @include media(down, $breakpoint-md) {
      font-size: size(22);
   }
}

.font-25 {
   font-size: size(25);

   @include media(down, $breakpoint-md) {
      font-size: size(20);
   }
}

.font-38 {
   font-size: size(38);
}

.font-47 {
   font-size: size(47);

   @include media(down, $breakpoint-md) {
      font-size: size(35);
   }
}

.font-50 {
   font-size: size(50);

   @include media(down, $breakpoint-sm) {
      font-size: size(40);
   }
}

.font-100 {
   font-size: 100%;
   line-height: 100%;
}

.font-14 {
   font-size: 14px;

   @include media(down, $breakpoint-md) {
      font-size: 12px;
   }
}

.font-13 {
   font-size: 13px;
}

.font-16 {
   font-size: 16px;
}

.font-18 {
   font-size: 18px;
}

.font-17 {
   font-size: 17px;
}

.text-white-20 {
   color: rgba($white, 0.20);
}

.text-white-55 {
   color: rgba($white, 0.55);
}

.text-white-25 {}

.fw-semibold {
   font-weight: $font-semibold;
}

.fw-medium {
   font-weight: $font-medium;
}

.line-clamp2 {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   text-overflow: ellipsis;
   overflow: hidden;
}

// Width class
.width-24 {
   width: 24px;
   height: 24px;
}

.width-28 {
   width: 28px;
   height: 28px;
}

.width-58 {
   width: size(50);
   height: size(50);
}

.height-446 {
   height: size(446);

   @include media(down, $breakpoint-xl) {
      height: 100%;
   }
}

@include media(down, $breakpoint-sm) {
   .w-sm-100 {
      width: 100%;
   }
}

// Max width Classes
.max-387 {
   max-width: 387px;
}

.max-558 {
   max-width: 558px;
}

.max-400 {
   max-width: 400px;
}

.max-650 {
   max-width: 650px;
}

.max-950 {
   max-width: 950px;
}

.min-w-195 {
   min-width: 195px;
}

.min-393 {
   min-width: 393px;

   @include media(down, $breakpoint-sm) {
      min-width: auto;
   }
}

.min-200 {
   min-width: 200px;
}

.min-255 {
   min-width: 255px;
}

.pointer {
   cursor: pointer;
}


.mb-50 {
   margin-bottom: size(50);

   @include media(down, $breakpoint-lg) {
      margin-bottom: size(30);
   }
}

.mb-70 {
   margin-bottom: size(70);

}

// Border
.border-secondary {
   border-color: $white !important;
}

.bg-secondary-light {
   background-color: $secondaryLight !important;
}

.rounded-3 {
   border-radius: 15px !important;
}

.block {
   >*:last-child {
      padding-bottom: 0;
      margin-bottom: 0 !important;
   }
}

.z-index-1 {
   z-index: 1;
}

.pointer-none {
   pointer-events: none;
}

.w-110 {
   width: 110px;
   height: 110px;
   border-radius: 50%;
}

.bg-lightgrey {
   background: #DCE1DE;
}

.box-block {
   border-radius: 20px;
   background: #DCE1DE;
   padding: 48px 32px;
}

.box-infoblock {
   color: $darkheader-bg;

}

/* Starts Css for Table */
.table {
   border-color: rgba($light, .2) !important;

   tr {
      th {
         font-size: size(18);
         font-weight: $font-medium;
         color: $white;
         border-color: rgba($light, .2) !important;
         padding: size(20) size(20);
         white-space: nowrap;
      }

      td {
         border-color: rgba($light, .2) !important;
         font-size: size(16);
         color: $white;
         padding: size(15) size(20);
      }
   }

   &> :not(:first-child) {
      border-color: rgba($light, .2) !important;
      border-top-width: 1px;
   }

   &>tbody {
      vertical-align: middle;
   }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   cursor: pointer;
   -webkit-appearance: none;
}

.mynumberclass input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   cursor: pointer;
   -webkit-appearance: none;
   background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   width: 1em;
   border-left: 1px solid #bbb;
   opacity: .5;
   /* shows Spin Buttons per default (Chrome >= 39) */
}



/* starts Css for progress bar */
.progress {
   background: #737373;
}

/* Starts Css for slick SLider */
.slick-arrow {
   width: 62px;
   height: 62px;
   top: auto;
   bottom: -112px;
   left: auto;
   right: -10px;
   background: url('./../../../../public/assets/images/back-arrow.svg') no-repeat center !important;

   // &:hover {
   //    filter: invert(65%) sepia(82%) saturate(5829%) hue-rotate(264deg) brightness(102%) contrast(114%);
   // }

   &::before {
      display: none;
   }
}

.slick-prev {
   // background: url('./../../../../public/assets/images/arrow-left.svg') no-repeat center !important;
   // background-size: contain !important;
   // margin-left: -40px;
}

.slick-next {
   // background: url('./../../../../public/assets/images/arrow-right.svg') no-repeat center !important;
   // background-size: contain !important;
   // margin-left: 40px;
   background: url('./../../../../public/assets/images/forward-arrow.svg') no-repeat center !important;
   right: 55px;

}

/* Starts Css for Offcanvas */
.offcanvas-backdrop {
   background-color: $black;

   &.show {
      opacity: 0.95;
   }
}

.offcanvas {
   @include media(down, $breakpoint-sm) {
      padding: 30px 30px;
      height: auto;
      max-height: 100%;
      top: 0;
   }
}

/* Form Control */
.form-control {
   border-radius: 20px;

   @include media(down, $breakpoint-sm) {
      padding: size(10) size(16);
   }
}

/* Starts Css for react range slider */
.rangeslider-horizontal {
   height: 9px;

   .rangeslider__fill {
      background-color: #111111;
      box-shadow: none;
   }
}

.rangeslider {
   background-color: #737373;
   box-shadow: none !important;

   .rangeslider__handle {
      background: #222222;
      box-shadow: none !important;
      border: 2px solid $light;
      @extend %flex;

      .rangeslider__handle-label {
         font-size: 12px;
         position: relative;
         line-height: 1;
      }

      &:after {
         display: none;
      }
   }
}

.tooltip {
   margin-bottom: size(10);
}

/* Modal css */
.modal-dialog {
   min-width: 750px;

   @include media(down, $breakpoint-sm) {
      min-width: auto !important;
   }

   .modal-content {
      background: #0D0D0D;
      border: 1px solid #535353;
      border-radius: 0;
   }
}

.modal-header {
   justify-content: center;
   border-bottom: 1px solid #535353;
   padding: size(15) size(30);

   .modal-title {
      font-size: size(30);
      color: $white;
      font-weight: $font-semibold;
   }

   .btn-close {
      background-color: #4F4F4F;
      @include border-radius(50%);
      position: absolute;
      top: size(20);
      width: size(25);
      height: size(25);
      right: size(20);
      opacity: 1;
      background-size: 42%;
   }
}

.modal-body {
   padding: size(30) size(30);

   @include media(down, $breakpoint-md) {
      padding: size(30) size(20);
   }
}

.modal-backdrop {
   background: $secondary;

   &.show {
      opacity: 0.85;
   }
}

.max-511 {
   max-width: 450px;
}

/* Starts Css for React Datepicker */
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
   display: none;
}

div {
   &.react-datepicker {
      border: 1px solid #3E3E3E;
      border-radius: 6px;
      font-family: $font-family-base;
      background-color: $body-bg;
      color: $white;

      .react-datepicker__header {
         background-color: $body-bg;
         border-bottom: 1px solid #3E3E3E;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name,
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
         color: $white;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
         background: $primary;
      }

      .react-datepicker__day:hover {
         color: $black !important;
      }
      .react-datepicker__time-list-item:hover {
         color: $black !important;
      }
      .react-datepicker__time-list-item--selected {
         background-color: $body-bg !important;
      }
      .react-datepicker__time-list-item--selected:hover {
         background-color: $body-bg !important;
         color: $white !important;
      }
      .react-datepicker__time {
         background-color: $body-bg;
      }
   }
}

body::-webkit-scrollbar {
   width: 1px;
}

body::-webkit-scrollbar-track {
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
   background-color: darkgrey;
   outline: 1px solid slategrey;
}

.scrool-more {
   height: 500px;
   overflow: scroll;
   overflow-x: hidden;
   scrollbar-width: thin;
}

.tree-dot {
   display: inline-block;
   width: 180px;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis;
}

.dropdown-toggle::after {
   display: none;
}

.edit-img {
   overflow: hidden;
   display: block;
   position: relative;

   .edit-btn-image {
      position: absolute;

      z-index: 1;

      @include media(down, $breakpoint-sm) {
         bottom: -9px;
      }
   }
}

.upload-icon {
   position: absolute;
   right: 0;
   width: 20px;
   bottom: 6px;
   height: 20px;
   cursor: pointer;
   padding: 4px;
   width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   border-radius: 50%;
   background: #9300ff;
   border: 1px solid #9300ff;
   right: 9px;
   justify-content: center;

   img {
      max-width: 22px;
      position: relative;
      top: 1px;
   }
}

.loading {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 1;
}

.spin {
   height: auto;
   width: auto;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.spin span {
   height: 156px;
   width: 156px;
   border: 1px solid #7015FF;
   display: block;
   border-radius: 50%;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   animation: spin 1500ms ease-in-out infinite;
}

.spin .one {
   animation-delay: 500ms;
   animation: spin 2000ms ease-in-out infinite;
}

.spin .two {
   animation: spin 1700ms ease-in-out infinite;
   animation-delay: 300ms;
   border: 10px solid #7015FF;
}

.spin .two {
   animation: spin 2000ms ease-in-out infinite;
   border-color: #7015FF;
}

@keyframes spin {
   0% {
      transform: scale(0);
      opacity: 1;
   }

   100% {
      transform: scale(1.5);
      opacity: 0;
   }
}

@keyframes hide {
   0% {
      opacity: 0;
   }

   10% {
      opacity: 1;
   }

   100% {
      opacity: 1;
   }
}

.toggle-label {
   position: relative;
   width: 330px;
   height: 40px;
   margin-top: 10px;
}

.toggle-label input[type=checkbox] {
   opacity: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
}

.toggle-label input[type=checkbox]+.back {
   position: absolute;
   width: 100%;
   height: 40px;
   left: 0;
   top: 0;
   border-radius: 50px;
   border: 1px solid #7015FF;
   transition: background 150ms linear;
   display: flex;
   align-items: center;
}

.toggle-label input[type=checkbox]:checked+.back {
   border: 1px solid #7015FF;
   cursor: pointer;
}

.toggle-label input[type=checkbox]+.back .toggle {
   display: block;
   position: absolute;
   content: ' ';
   background: #7015FF;
   width: 50%;
   height: 100%;
   transition: margin 150ms linear;
   border: 1px solid #808080;
   border-radius: 50px;
}

.toggle-label input[type=checkbox]:checked+.back .toggle {
   margin-left: 164px;
}

.toggle-label .label {
   display: block;
   position: absolute;
   width: 50%;
   color: #fff;
   line-height: 38px;
   text-align: center;
   font-size: size(14)
}

.toggle-label .label.on {
   left: 0px;
}

.toggle-label .label.off {
   right: 0px;
}

.toggle-label input[type=checkbox]:checked+.back .label.on {
   color: #fff;
}

.toggle-label input[type=checkbox]+.back .label.off {
   color: #ddd;
   cursor: pointer;
}

.toggle-label input[type=checkbox]:checked+.back .label.off {
   color: #ddd;
}

.toggle-public-label {
   position: relative;
   width: 70px;
   top: -10px;
   left: 5px;

}

.toggle-public-label input[type=checkbox] {
   opacity: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
}

.toggle-public-label input[type=checkbox]+.back {
   position: absolute;
   width: 100%;
   height: 20px;
   left: 0;
   top: 0;
   border-radius: 50px;
   border: 1px solid #fff;
   transition: background 150ms linear;
}

.toggle-public-label input[type=checkbox]:checked+.back {
   border: 1px solid #fff;
   cursor: pointer;
}

.toggle-public-label input[type=checkbox]+.back .toggle {
   display: block;
   position: absolute;
   content: ' ';

   width: 50%;
   height: 100%;
   transition: margin 150ms linear;
   border: 1px solid #7015FF;
   border-radius: 50px;
   top: 0.4px;
}

.toggle-public-label input[type=checkbox]:checked+.back .toggle {
   margin-left: 34px;
}

.toggle-public-label .label {
   display: block;
   position: absolute;
   width: 50%;
   color: #fff;

   text-align: center;
   font-size: size(14)
}

.toggle-public-label .label.on {
   left: 0px;
}

.toggle-public-label .label.off {
   right: 0px;
}

.toggle-public-label input[type=checkbox]:checked+.back .label.on {
   color: #fff;
}

.toggle-public-label input[type=checkbox]+.back .label.off {
   color: #ddd;
   cursor: pointer;
}

.toggle-public-label input[type=checkbox]:checked+.back .label.off {
   color: #ddd;
}

.svg-btn-footer {

   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(101%) contrast(103%);

}

.svg-btn-image {
   max-width: 25px;
   max-height: 25px;
   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(101%) contrast(103%);

   &:hover {
      filter: invert(35%) sepia(100%) saturate(7500%) hue-rotate(263deg) brightness(94%) contrast(115%);
   }
}

.svg-btn-image-no-hover {
   max-width: 25px;
   max-height: 25px;
   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(101%) contrast(103%);

}
.svg-btn-image-new {
   &:hover {
      filter: invert(35%) sepia(100%) saturate(7500%) hue-rotate(263deg) brightness(94%) contrast(115%);
   }
}
.con_button {
   flex-direction: row-reverse;

   @include media(down, $breakpoint-sm) {
      flex-direction: column !important;
   }
}

.wrapper {
   text-align: center;
}

.custom_i {
   margin-top: 3px;
   position: absolute;
}

.border_less {
   border: 0px !important;
   background-color: #089f45;
   transition: all .3s ease;

   &:hover {
      background-color: #089f45 !important;
   }
}

.border-color-on-hover-white {
   &:hover {
      cursor: pointer;
      box-shadow: -1px 0 1px 1px rgba(255, 255, 255, 0.75), inset -1px 0 0 1px rgba(255, 255, 255, 0.75);
   }
}

.on_trial {
   text-decoration: line-through;
}

.border_less_link {
   border: 0px !important;
}

.cancle_width {
   width: 70%;

   @include media(down, $breakpoint-sm) {
      width: 100% !important;
   }
}

.text-right {
   text-align: right !important;
}

.new_button {
   max-width: 330px;
   font-size: 14px;

   @include media(up, $breakpoint-xxxl) {
      max-width: 330px !important;
      font-size: 18px !important;
   }

   @include media(down, $breakpoint-lg) {
      max-width: 280px !important;
   }

   @include media(down, $breakpoint-md) {
      max-width: 180px;
   }

   @include media(down, $breakpoint-sm) {
      max-width: 180px;
   }
}

.font-variation {
   @include media(up, $breakpoint-xxxl) {
      font-size: 21px !important;
   }
}

.large_box {
   justify-content: center;
}

.large_font {
   @include media(down, $breakpoint-sm) {
      font-size: 18px !important;
   }
}


.inlending-details {
   color: $white;
}


// Starts Css for accordion
.accordion-button {
   font-weight: 500;
   font-size: 24px;
   padding: 16px 0;
   background: transparent;
   color: #DCE1DE !important;
   box-shadow: none !important;
   outline: 0;

   &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
      filter: invert(0%) sepia(52%) saturate(66%) hue-rotate(66deg) brightness(91%) contrast(93%);
   }

   &:not(.collapsed) {
      background-color: transparent;
   }

   @include media(down, $breakpoint-sm) {
      font-size: 16px;
      padding: 8px 0px;
   }
}

.accordion-body {
   font-weight: 300;
   font-size: 20px;
   color: #DCE1DE;
   padding: 0;

   @include media(down, $breakpoint-sm) {
      font-size: 13px;
      padding-bottom: 8px;
   }
}

.accordion-item {
   padding: 16px 0;
   border-bottom: 1px solid #DCE1DE;
   background: transparent;

   .accordion-item {
      transition: color 0.2s;
   }

   &:hover {
      .accordion-button {
         color: $primary !important;

         &::after {
            // filter: invert(65%) sepia(82%) saturate(5829%) hue-rotate(264deg) brightness(102%) contrast(114%);
         }
      }
   }

   &:first-child {
      padding-top: 0;
   }

   @include media(down, $breakpoint-sm) {
      padding: 3px;
   }

}

.future-slider {

   .slick-arrow.slick-prev,
   .slick-arrow.slick-next {
      @include media(down, $breakpoint-sm) {
         display: none !important;
      }
   }
}

.slick-track {
   display: flex;

   @include media-breakpoint-up(lg) {
      justify-content: center;
   }
}

.m-zindex {
   z-index: 100;
}

.titleheader-box {
   .title-header {
      &:last-child {
         border-bottom: 0;
      }
   }
}

.readmore-btn {
   color: rgba($svart, 0.6);

   &:hover {
      color: $primary;
   }
}
.align-items-center-mob{
   @include media(down, $breakpoint-sm) {
      align-items: center;
   }
}

.width33 {
   width: calc(33.33% - 14px);
}

.allcategories-wrap {
   border-radius: 20px;
   background: #FEFEFF;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
   padding: 12px 20px;
}

.videodash-block {
   padding-bottom: 36%;
   height: 0;
   position: relative;
   text-align: center;
   max-width: 392px;
   border-radius: 18px 18px 0 0;
   overflow: hidden;
   margin: 0 auto;

   iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
   }
}

.filter-invert {
   filter: invert(1);
}

.max-w-392 {
   max-width: 392px;
}

.max-w-445 {
   max-width: 445px;
}

.bottom-22px {
   bottom: 22px;
}
.bottom-12px {
   bottom: 6px;
}
.bottom-6px {
   bottom: 6px;
}

.video-progress {
   border-radius: 0px 0px 44.898px 44.898px;

   .progress-bar {
      background: $green !important;
   }
}

.videotext-block {
   padding: 0 12px;
}

.videoprogress-block {
   &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 17.959px 17.959px 0px 0px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.2%, #000 90.39%);
   }
}

// .btn-primary.primarybtn-hover {
//    &:hover {
//       background-color: $primary !important;
//    }
// }

.tabblock-box {
   padding: 4px;
   border-radius: 20px;
}

.leftbox-header {
   width: 40.33%;
}

.btn-tablink {
   padding: 6px 20px;
   border-radius: 20px;
   background: transparent;
   transition: all 0.1s ease;
   display: inline-block;
   cursor: pointer;

   &.active {
      background: #FFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
   }
}

.tableblock-wrap {
   tr th {
      background: $white !important;
      color: $svart !important;

      &:first-child,
      &:last-child {
         border-left: 0 !important;
      }

      &:hover {
         background: $white !important;
      }
   }

   &.table-bordered> :not(caption)>* {
      border-color: rgba($black, .2) !important;
   }

   tr {
      &:last-child {
         border-bottom: 0 !important;
      }

      td {
         background: $white !important;
         color: $black !important;
         font-weight: 300;

         &:first-child,
         &:last-child {
            border-left: 0 !important;
         }

         &:hover {
            background: $white !important;
         }
      }

      &:hover {
         background: $white !important;
      }
   }
}

.table-borderwrap {
   .table-responsive {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

      td,
      th {
         border-color: rgba($black, .2) !important;

         &:last-child {
            border-right: 0 !important;
         }
      }
   }

   .table-shadow {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      background: #fff;
      overflow: hidden;

      .nodata-block {
         padding: 10px;
         border-top: 1px solid rgba(6, 6, 6, 0.2) !important;
         margin: 0 !important;
         font-size: 20px;
         font-weight: 300;

         @include media-breakpoint-down (md) {
            font-size: 14px;
         }
      }

      .table-responsive {
         border-radius: 0;
         box-shadow: none;
         overflow-y: hidden;
      }
   }
}

.btnbox-block {
   position: relative;
   z-index: 9;
}

.userdropdown {
   .dropdown-menu {

      right: 0 !important;
      left: auto !important;
      top: 10px !important;
      border-radius: 17px;
      background: $white;
      box-shadow: 0px 4px 34px -1px rgba(0, 0, 0, 0.65);
      width: 220px;

      .dropdown-item {
         color: $black;
         font-size: 20px;
         background: transparent;
         font-weight: 300 !important;
         padding: 4px 16px;

         &:hover {
            color: $primary !important;
         }
      }
   }
}

.rounded-20px {
   @include border-radius(20px);
}

.max-w-469 {
   @include media-breakpoint-up(md) {
      max-width: 469px;
   }
}

.mobile-imgfull {
   @include media-breakpoint-down(md) {
      width: 100%;
   }
}

.btnshadow-block {
   border-radius: 18.589px;
   box-shadow: 3.71776px 3.71776px 6.50608px 0px rgba(0, 0, 0, 0.25) !important;
   width: 318px;
   max-width: 100%;
}

.profile-hr {
   border-color: rgba($svart, 0.2);
   margin: 8px 0;
}

.sliderbox-wrap {
   .slick-list {
      text-align: center;
   }

   .slick-arrow {
      border-radius: 560px;
      background: #F5F7F9 !important;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40);
      width: 28px;
      height: 28px;
      transform: translateY(-50%);
      right: 12px;
      top: 50%;
      bottom: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &.slick-prev {
         right: auto;
         left: 12px;

         &::before {
            transform: rotate(180deg);
         }
      }

      &::before {
         content: '';
         display: block;
         background: url('./../../../../public/assets/images/arrow-rightblock.svg') no-repeat center !important;
         width: 10px;
         height: 15px;
         filter: invert(1);
         margin: auto;
         background-size: contain !important;
      }
   }

   .slick-dots {
      bottom: -14px;

      li {
         width: 8px;
         height: 8px;
         margin: 0 3px;

         button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
               background: rgba(25, 25, 25, 0.30);
               border: 0;
               width: 100%;
               height: 100%;
            }
         }

         &.slick-active {
            button {
               &::before {
                  background-color: $svart;
               }
            }
         }
      }
   }
}

.modal-innerbox {
   .modal-dialog {
      max-width: 500px !important;
      min-width: auto !important;
   }

   .modal-header {
      justify-content: flex-start;
      color: $svart;
      align-items: flex-start;
      border-color: rgba(0, 0, 0, 0.2);
      flex-direction: column;
      padding: 20px;

      .modal-title {
         color: $svart;
         font-weight: 500;
         line-height: 1;
         font-size: 24px;
      }

      span {
         display: block;
         opacity: 0.6;
         font-size: 20px;
         margin-top: 8px;
         font-weight: 500;

      }
   }

   .btn-close {
      background-color: transparent;
      filter: invert(0);
      width: 20px;
   }

   .modal-content {
      border-radius: 19.749px;
      background: #FFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
   }
}

.btn-blocksec {
   padding: 10px 26px !important;
   border-radius: 20px;
}

.forminput-blockwrap {
   color: rgba($black, 0.2) !important;
   &.bg-grey-block{
      color: #101010 !important;
      @include placeholder{
         color: #757575;
      }
   }
}

.single-select {
   width: 20%;
   max-width: 40%;
   margin: auto;
   background-color: white;
   margin-bottom: 20px;
   margin-top: 20px;
   border-radius: 20px;

   @include media(down, $breakpoint-lg) {
      width: 100% !important;
      max-width: 40% !important;
   }

   @include media(down, $breakpoint-sm) {
      width: 100% !important;
      max-width: 60% !important;
   }
}

.thumbnail-podcast-img {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.single-selectionblock {
   width: 57%;
   display: flex;
   align-items: center;

   @include media-breakpoint-down(lg) {
      width: 100%;
      justify-content: center;
   }
}

.header-selection {
   .myselect__indicator-separator {
      display: none !important;
   }

   .myselect__control {
      border-radius: 20px;
      opacity: 1;
      background: #FEFEFF;

      /* Dropshadow */
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

      .myselect__placeholder {
         color: #191919 !important;
         font-weight: 300 !important;
         opacity: 1 !important;
      }

      .myselect__single-value {
         color: #191919 !important;
         font-weight: 300 !important;
      }
   }


}
.myselect__option {
background-color: $white !important;
   &:hover {
      color: $primary !important;
   }
}
.myselect__placeholder,
.myselect__option {
   font-size: 20px !important;
   color: $svart !important;
   @include media-breakpoint-down(lg) {
      font-size: 13px !important;
    }
}
.myselect__option--is-selected {
   color: $primary !important;
   background-color: $white !important;
}
.filter-invert {
   filter: invert(1);
}
.myselect__placeholder_white {
   .myselect__placeholder {
      color: $white !important;
   }
}
.top-bordernone {
   tr {
      border-top: 0 !important;
      border: 0 !important;
   }
}

.border-top-none {
   border-top: 0 !important;
   border: 0 !important;
}

.selectinfo-box {
   .form-check {
      display: flex;
      align-items: center;
      padding-left: 0;

      .form-check-label {
         order: 0;
      }

      .form-check-input {
         position: relative;
         margin: 0 0 0 8px;
         order: 1;
      }
   }
}

.select-design {}

.black-friday {
   min-height: 100vh !important;

   &.banner-sec {
      &::after {
         @include media(down, $breakpoint-sm) {
            background: none !important;
         }
      }
   }
}


.linkbox-wrap {
   font-weight: 300 !important;
   opacity: 0.6;
}

.btnblock-link {
   padding: 10px 26px !important;
}

.only_mobile {
   @include media(down, $breakpoint-sm) {
      display: block !important;
   }
}

.hide_in_mobile {
   @include media(down, $breakpoint-sm) {
      display: none !important;
   }
}

.btngreen-box {
   border-radius: 26.407px;
   background: linear-gradient(266deg, #0B6E4F 2.28%, #08A045 63.64%);
   font-size: 16px;
   color: $white;
   padding: 10px 26px;
   display: inline-block;
   font-weight: 400;
}

.title-mobile-box {
   @include media-breakpoint-down(md) {
      background-color: $white !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px 20px 0 0 !important;
      position: relative;
   }
}

.box-mobileblock {
   @include media-breakpoint-down(md) {
      border-radius: 0 0 20px 20px !important;

   }
}

.card-link {
   font-size: 16px;
   font-weight: 500;
   color: $svart;
   border-bottom: 1px solid transparent;
   padding-bottom: 4px;
   @include media-breakpoint-down(sm) {
      font-size: 13px;
   }
   &.active {
      border-bottom-color: $svart;
   }
}

.mobileinfo-block {
   @include media-breakpoint-down(md) {
      display: none !important;

      &.active {
         display: block !important;
      }
   }
}

.content-blokcwysiwg {
   p {
      margin-bottom: 10px;
   }
}

.width-90 {
   @include media(down, $breakpoint-sm) {
      width: 90% !important;
   }
}

.individule_button {
   @include media(down, $breakpoint-sm) {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
   }
}

// Progressbar
.green-progressbar {

   &.progress,
   .progress-stacked {
      height: 24px;
      border-radius: 20px;
      color: $white;

      @include media(down, $breakpoint-sm) {
         height: 13px;
         border-radius: 10px;
      }
   }

   .progress-bar {
      background: #08A045 !important;
      font-size: 16px;

      @include media(down, $breakpoint-sm) {
         font-size: 10px;
      }
   }
}

.max-565 {
   max-width: 565px;
}
.max-600 {
   max-width: 600px;
}

.boxlink {
   &:hover {
      border-color: $primary !important;
      cursor: pointer;
   }
}

.img-overlay-content{
   overflow: hidden;
   .overlay-info{
     position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgb(255 255 255 / 80%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $primary;
   }
}
.chat-wrapper{
   background-color: #FEFEFF;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   border-radius: 20px;
   .chat-header{
      padding: 20px;
      border-bottom: 1px solid rgb(0 0 0 / 20%);
      @include media(down, $breakpoint-sm) {
         padding:16px;
      }
   }
   .chat-body{
      padding: 20px;
      min-height: 250px;
      max-height: 250px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      @include media(down, $breakpoint-sm) {
         padding:16px;
      }
   }
   .chat-footer{
      padding: 20px;
      border-top: 1px solid rgb(0 0 0 / 20%);
      @include media(down, $breakpoint-sm) {
         padding:16px;
      }
      .form-control{
         height: 40px;
         min-height: 40px;
         background-color: #F5F7F9;
         color: #000000 !important;
         border-color: transparent;
         font-size: 14px;
         @include placeholder{
            color: rgb(0 0 0 / 50%) !important ;
         }
      }
      .btn{
         height: 40px;
         display: flex;
         align-items: center;
      }
   }
   .chat-msg-box{
      background-color: #F5F7F9;
      padding: 10px 20px;
      border-radius: 20px 20px 0px 20px;
      text-align: left;
      width: fit-content;
      margin-left: auto;
      margin-bottom: 10px;
      // margin-top: auto;
      max-width: 95%;
   }
   .chat-user-ic{
      svg{
         @include media(down, $breakpoint-sm) {
            max-width: 16px;
         }
      }
   }
}
a.disabled-link {
   pointer-events: none;
   cursor: default;
   opacity: 0.3;
 }
 
 .max-w-100{
   max-width: 100% !important;
 }

 .empty-gif{
   width: 480px;
   height: 400px;
   margin: 0 auto;
   @include media(down, $breakpoint-sm) {
      width: 100%;
      height: 292px;
   }
   img{
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
 }
 .btn-topborder{
   border-top: 1px solid rgba(0, 0, 0, 0.25);
 }