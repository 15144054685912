.info-count {
   font-size: size(24);
   letter-spacing: 0.02em;
   width: size(60);
   height: size(60);
   border: 1px solid $light-gray;
   color: $white;
   @include border-radius(50%);

   @include media(down, $breakpoint-xxl) {
      width: size(50);
      height: size(50);
   }
}

.info-text {
   color: rgba($white, 0.25);
}

.info-box {
   border: 1px solid $light-gray;
   height: 80px !important;
   margin-bottom: 15px;
   cursor: pointer;
   @include border-radius(12px);
   padding: size(15) size(30);

   @include media(down, $breakpoint-md) {
      padding: size(15) size(20);
   }

   &:hover {
      border: 1px solid $primary;
      cursor: pointer;
   }
}

.card-pimg {
   img {
      @include border-radius(50%);
   }
}

.card-personblock {
   width: 142px;

   @include media(down, $breakpoint-lg) {
      width: 100%;
      justify-content: center;
      margin-top: size(20);
   }

   >* {
      width: size(40);
      height: size(40);
      @include border-radius(50%);
      background: #B1B1B1;
      @extend %flex;
      color: #1A1919 !important;
      font-size: 14px;

      &:not(:first-child) {
         margin-left: -10px;
      }
   }
}

.card-personinfo {
   width: calc(100% - 142px - 6.875rem);
}

.card-pinfo {
   padding: size(20) size(30);

   @include media(down, $breakpoint-lg) {
      flex-wrap: wrap;
   }

   @include media(down, $breakpoint-md) {
      padding: size(20) size(20);
   }

   &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
   }
}

.card-block {
   border: 1px solid $light-gray;
   @include border-radius(12px);

   .card-header {
      border-bottom: 1px solid $light-gray;
      padding: size(20) size(30);

      @include media(down, $breakpoint-md) {
         padding: size(20) size(20);
      }
   }
}

.notif-round {
   @include border-radius(50%);
   border: 1px solid $light-gray;
}

.notification-info {
   width: calc(100% - 4.625rem);
}

.notif-time {
   width: 120px;
   text-align: right;

   @include media(down, $breakpoint-lg) {
      width: 100%;
      text-align: left;
      color: $light;
      margin-top: size(10);
   }
}

.notification-text {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   text-overflow: ellipsis;
   overflow: hidden;
}

.notification-innerblock {
   padding: size(15) size(30);

   @include media(down, $breakpoint-lg) {
      flex-wrap: wrap;
   }

   @include media(down, $breakpoint-md) {
      padding: size(15) size(20);
   }
}

.graph-block {
   min-height: 384px;
}

.dashboard-sliderbox {
   &.sliderbox-wrap {
      .slick-list{
         border-radius: 17px;
      }
      .slick-dots{
         @include media(down, $breakpoint-sm) {
             bottom: -22px 
         }
      }
      .slick-arrow {
         &.slick-prev {
            left: -40px;
            z-index: 1;
            @include media(down, $breakpoint-sm) {
               top: 102%;
               transform: translateY(0) translateX(-50%);
               background: transparent !important;
               border: none;
               box-shadow: none;
               left: 35%;
            }
         }

         &.slick-next {
            right: -40px;
            z-index: 1;
            @include media(down, $breakpoint-sm) {
               top: 102%;
               transform: translateY(0) translateX(-50%);
               background: transparent !important;
               border: none;
               box-shadow: none;
               right: 26%;
            }
         }
      }
   }
}
.dashboard-shadow-box {
   padding: 16px 54px 36px;
   @include media(down, $breakpoint-xxl) {
      padding: 16px 20px 30px;
   }
   @include media(down, $breakpoint-sm) {
      padding: 16px 16px 40px;
   }
}