.question-info{
   // border-bottom: 1px solid rgba($black, 0.23);
   // > *{
   //    padding: size(20) 0;
   //    @include media(down, $breakpoint-md){
   //       padding: size(24) 0;
   //    }
   // }
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
   margin-left: 11px;
}
.question-block{
   // border: 1px solid $light-gray;
   max-width: 1300px;
   &:not(:last-child){
      margin-bottom: size(40);
   }
}
.answer-options{
   // padding: size(45) size(30);
   // @include media(down, $breakpoint-md){
    //  padding: size(25) size(40);
   // }
   .form-check{
    //  padding-left: 0;
      margin-left: 5px;
      margin-bottom: 5px;
      input {
         border: 1px solid rgba($black, 0.23);
      }
      label{
         display: flex;
         align-items: center;
         font-size: size(20);
         font-weight: 300;
         color: $black; 
         font-style: normal;
         line-height: normal;
         text-align: left;
      }
   }
}
.answer-testmodal{
//   .testimonial-block {
//    .slick-dots{
//       bottom: 12px;
//       left: 50%;
//       transform: translateX(-50%);
//    }
//    .slick-arrow{
//       bottom: 0;
//       &.slick-prev{
//          left: calc(50% - 30px );
//       }
//       &.slick-next{
//          left: calc(50% + 30px);
//       }
//    }
   
//   }
//   .slick-slider{
//    position: relative;
//    padding-bottom: 50px;
//    &:before{
//       content: '';
//       border-top: 1px solid rgba(0, 0, 0, 0.2);
//       position: absolute;
//       left: -26px;
//       right: -26px;
//       bottom: 34px;
//    }
//   }
  .slick-slide{
   padding: 20px;
  }
  .slick-track{
   display: flex;
   align-items: flex-start;
  }
  .modal-footer{
      margin: 0;
      padding: 0;
  }
}
.manual-slider-action {
   border-top: 1px solid rgba(0, 0, 0, 0.2);
   padding: 20px;
   .slick-arrow{
      position: static;
      &.slick-prev{
         transform: translate(0, 0);
      }
      &.slick-next{
         transform: translate(0, 0);
      }
    }
    .last-button{
      position: absolute;
      right: 20px;
      @include media(down, $breakpoint-md){
         position: static;
      }
    }
}
.testimonial-block {
   .slick-dots li {
       width: auto;
       height: auto;
       display: none;

       &.slick-active {
           display: block;
       }
   }

   .slick-arrow {
       bottom: 11px;
       top: auto !important;
       z-index: 99;

     

       &.slick-prev {
           background: url('./../../../../public/assets/images/arrow-left.svg') no-repeat;
           left: 0;
       }

       &.slick-next {
           background: url('./../../../../public/assets/images/arrow-right.svg') no-repeat;
           left: 75px;
           right: auto;
       }

       background-size: contain !important;
   }

   .slick-dots {
       width: auto;
       border-radius: 45px;
      // border: 1px solid #191919;
       font-size: 20px;
       color: #191919;
       font-weight: 500;
       padding: 2px 10px;
       position: absolute;
       bottom: 24px;
       left: 33px;

      
   }
}
.form-check-black{
   .form-check-input{
      border: 1px solid rgba($black, 0.23);
   }
}