.btn {
  @include border-radius(50px);
}

.mt-100 {
  margin-top: 100px;

  @include media(up, $breakpoint-xxxl) {
    margin-top: 150px;
  }

  @include media(down, $breakpoint-lg) {
    margin-top: 70px;
  }

  @include media(down, $breakpoint-md) {
    margin-top: 50px;
  }

  @include media(down, $breakpoint-sm) {
    margin-top: 20px;
  }
}

.mt-85 {
  margin-top: 85px;

  @include media(up, $breakpoint-xxxl) {
    margin-top: 85px;
  }

  @include media(down, $breakpoint-lg) {
    margin-top: 50px;
  }

  @include media(down, $breakpoint-md) {
    margin-top: 30px;
  }

  @include media(down, $breakpoint-sm) {
    margin-top: 20px;
  }
}

.mt-70 {
  margin-top: 70px;

  @include media(up, $breakpoint-xxxl) {
    margin-top: 70px;
  }

  @include media(down, $breakpoint-lg) {
    margin-top: 50px;
  }

  @include media(down, $breakpoint-md) {
    margin-top: 30px;
  }

  @include media(down, $breakpoint-sm) {
    margin-top: 20px;
  }
}

.my-70 {
  margin: 100px 0;

  @include media(up, $breakpoint-xxxl) {
    margin: 70px 0;
  }

  @include media(down, $breakpoint-lg) {
    margin: 80px 0;
  }

  @include media(down, $breakpoint-md) {
    margin: 50px 0;
  }

  @include media(down, $breakpoint-sm) {
    margin: 30px 0;
  }
}

.mb-100 {
  margin-bottom: 100px;

  @include media(up, $breakpoint-xxxl) {
    margin-bottom: 150px;
  }

  @include media(down, $breakpoint-lg) {
    margin-bottom: 70px;
  }

  @include media(down, $breakpoint-md) {
    margin-bottom: 50px;
  }

  @include media(down, $breakpoint-sm) {
    margin-bottom: 20px;
  }
}

.pt-100-acurate {
  padding-top: 125px;

  @include media(down, $breakpoint-sm) {
    padding-top: 100px;
  }
}

.pt-100 {
  padding-top: 120px;

  @include media(down, $breakpoint-md) {
    padding-top: 50px;
  }
  @include media(down, $breakpoint-sm) {
    padding-top: 20px;
  }
}

.pt-80 {
  padding-top: 80px;
}

.py-60 {
  padding: var(--py-60);
}

.py-100 {
  padding: var(--py-100);
}

.py-80 {
  padding: var(--py-80);
}

.pb-100 {
  padding-bottom: 120px;

  @include media(down, $breakpoint-md) {
    padding-bottom: 50px;
  }
}
.pb-80-mob{
  @include media(down, $breakpoint-md) {
    padding-bottom: 80px;
  }
}

p {
  word-break: break-all;
}

.main-block {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .main-blockwrap {
    padding: 250px 0 100px;

    @include media(down, $breakpoint-md) {
      padding: 150px 0 100px;
    }
  }
}

.font-medium {
  font-size: 56px;
  line-height: 68px;

  @include media(up, $breakpoint-xxxl) {
    font-size: 70px;
    line-height: 72px;
  }
  @include media(down, $breakpoint-lg) {
    font-size: 44px;
    line-height: 52px;
  }
  @include media(down, $breakpoint-md) {
    font-size: 26px;
    line-height: 36px;
  }
  @include media(down, $breakpoint-sm) {
    font-size: 25px;
    line-height: 32px;
  }
}

.bg-gray {
  background: $gray-shade2;
}

.green-bg {
  // background: #00A24E;
  background: url("../../../../public/assets/images/green_back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.dark-background {
  background: url('../../../../public/assets/images/docens_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background: #101010;
}

.mt-1 {
  margin-top: 5px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-3 {
  margin-top: 20px !important;
}

.mt-4 {
  margin-top: 30px !important;

  @include media(down, $breakpoint-sm) {
    margin-top: 20px !important;
  }

  @include media(down, $breakpoint-sm) {
    margin-top: 15px !important;
  }
}
.mt-5 {
  margin-top: 40px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mb-3 {
  margin-bottom: 20px !important;
}

.mb-4 {
  margin-bottom: 30px !important;

  @include media(down, $breakpoint-sm) {
    margin-bottom: 20px !important;
  }
}

.mb-5 {
  margin-bottom: 40px !important;

  @include media(down, $breakpoint-sm) {
    margin-bottom: 30px !important;
  }
}

.mb-6 {
  margin-bottom: 70px !important;

  @include media(down, $breakpoint-sm) {
    margin-bottom: 60px !important;
  }
}



.pt-1 {
  padding-top: 5px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 20px !important;
}

.pt-4 {
  padding-top: 30px !important;

  @include media(down, $breakpoint-sm) {
    padding-top: 20px !important;
  }
}

.pt-5 {
  padding-top: 40px !important;

  @include media(down, $breakpoint-sm) {
    margin-bottom: 30px !important;
  }
}



.pb-6 {
  padding-bottom: 50px !important;
}



.pb-6 {
  padding-bottom: 50px !important;
}
.pb-80px {
  padding-bottom: 80px !important;
}

.mw-500 {
  max-width: 500px;
}
.mw-720 {
  max-width: 720px;
}



video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.banner-content-area{
  position: relative;
  z-index: 1;
}
.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  // @include media(down, $breakpoint-sm){
  //   height: 300px;
  // }


}

.banner-images {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.banner-wrapper {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, transparent 0, transparent 30%, #000);
    opacity: 0.8;
  }
}

.banner-text {
  z-index: 100;
  max-width: 507px;

  @include media(down, $breakpoint-md) {
    max-width: 100%;

  }

}


.dropdown-menu {
  background-color: $light-gray;

  .dropdown-item {
    background-color: $light-gray;
    color: $white;
  }
}

.intl-tel-input {
  .country-list {
    background-color: $light-gray !important;
    border: 1px solid $light-gray !important;
    .divider {
      border-bottom: 1px solid $secondary !important;
    }
  }
}

.ct-btn {
  @include media(down, $breakpoint-sm) {
    align-items: center;
    justify-content: center;
  }
}

.found-text {
  border: 1px solid $white;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0 3px 10px rgba(0 ,0 ,0 ,0.2);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 20%);
  padding: 120px 20px;
  max-width: 600px;
  margin: 0 auto;
  @include media(down, $breakpoint-sm) {
    padding: 50px 15px;
  }
}


.custom-select {
  .css-1s2u09g-control {
    width: 100%;
    padding: 6px 1.25rem;
    font-family: "Clash Grotesk";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #3e3e3e;
    appearance: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      border-color: #3e3e3e;
    }

    &:focus {
      border-color: #7015FF;
    }
  }

  .css-1pahdxg-control {
    width: 100%;
    padding: 6px 1.25rem;
    font-family: "Clash Grotesk";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #3e3e3e;
    appearance: none;
    border-radius: 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;

    &:hover {
      border-color: #3e3e3e;
    }

    &:focus {
      border-color: #7015FF;
    }
  }

  .css-6j8wv5-Input {
    color: $white;
  }

  .css-b62m3t-container {
    &:focus {
      border: 1px solid #7015FF;
    }
  }

  .css-319lph-ValueContainer {
    color: $white;
  }

  .css-qc6sy-singleValue {
    color: $white;
  }

  .css-26l3qy-menu {
    background-color: $light-gray;
    z-index: 99;

    .css-yt9ioa-option {
      background-color: $light-gray;
    }

    .css-1n7v3ny-option {
      background-color: $dark;
    }

    .css-9gakcf-option {
      background-color: $dark;
    }
  }
  // .myselect__option--is-focused {
  //   background-color: $dark;
  // }
 
}
.myselect__menu-portal{
  .myselect__menu {
    background-color: #F5F7F9;
    border-radius: 16px;
    overflow: hidden;
    width: auto !important;
  }
}
.custom-select{
  &.dark-select{
    .myselect__control{
      background-color: transparent;
      border: 1px solid #3E3E3E !important;
      min-height: 60px;
      border-radius: 20px;
      box-shadow: none !important;
      @include media(down, $breakpoint-sm){
        min-height: 40px;
        border-radius: 12px;
     }
    }
    .myselect__value-container{
      @include media(down, $breakpoint-sm){
        padding: 6px 12px;
      }
    }
    .myselect__single-value{
      color: $white;
      font-size: 18px !important;
      font-weight: 400;
      @include media(down, $breakpoint-sm){
        font-size: 13px !important;
      }
    }
    .myselect__indicator-separator{
      width: 0;
    }
  }
  .myselect__menu {
    z-index: 9;
    border-radius: 10px;
    overflow: hidden;
  }
}
.myselect__control {
  .myselect__value-container {
    padding: 6px 16px;
  }
  border: 1px solid #3E3E3E !important;
  box-shadow: none !important;
}
.ElementsApp {
  .InputElement {
    color: $secondary !important;
  }
}

// .hv-100{
//   min-height: 100vh;
// }

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

// .hv-100{
//   min-height: 100vh;
// }

.optionListContainer {
  background-color: $light-gray !important;
  z-index: 99 !important;
}

.multiSelectContainer li:hover {
  background-color: #3E3E3E !important;
}

.highlightOption {
  background-color: $light-gray !important;
}

.chip {
  background: #7015ff !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background: $dark !important;
}

.ck.ck-toolbar {
  background: $dark !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset,
.ck.ck-reset_all {
  color: white !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: $dark !important;
}
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background: $dark !important;
}
.ck.ck-dropdown__panel {
  background: $dark !important;
  color: white !important;
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 150px;
}

.progress-bar {
  background: #7015ff !important;
}




.ck.ck-list {
  background: #3E3E3E !important;
}

.ck {
  &.ck-editor {
    &:focus-visible {
      outline: none !important;
    }
  }
}

.ck.ck-editor__main>.ck-editor__editable:focus-visible:focus {
  box-shadow: none !important;
}


.ck .ck-content {
  &:focus-visible {
    outline: none !important;
  }
}

.btn {
  @include media(up, $breakpoint-xxxl) {
    font-size: 24px;
    padding: 16px 40px;
  }
}

.myspan span {
  padding: 0px !important;
}

p {
  word-break: break-word !important;
}
.no-word-breack { 
  p {
    word-break:break-all !important; 
  }
  
}

.full-width {
  width: 100% !important;
}

.btn-primary {
  text-transform: none !important;
  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}

.in_green_back {

  &:hover,
  &:focus {
    box-shadow: -1px 0 1px 1px rgba(255, 255, 255, 0.75), inset -1px 0 0 1px rgba(255, 255, 255, 0.75);
  }
}

.navbar-brand-img:hover {
  fill: #7015ff;
}

.react-datepicker__day--disabled {
  color: #3e3e3e !important;
}

.nav-link {
  padding: 0 !important;
}

.cursor_class {
  cursor: pointer;
}
.display-class {
  text-align: center;
  display: block;
}
.img-header-green {
  .btn-img {
    background: $success;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    min-width: 75px;

  }
}

.img-content-green {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0 !important;
  left: 0 !important;
  padding: 20px;
  width: 100% !important;
  height: 100px !important;
  z-index: 1;
  .img-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 1;
  }
}

.tree-dot {
  width: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  height: 20px;
}
.categories-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 565px;
  margin: 0 auto 40px;
}
.feature-card-wrap {
  display: flex;
  text-align: center;
  margin-top: 45px;

  @include media(down, $breakpoint-sm) {
    margin-top: 30px;
  }
  .col-md-12 {
    @include media(down, $breakpoint-lg) {
      margin-bottom: 15px;
    }
  }

  .feature-box {
    border-radius: 20px;

    @include media(down, $breakpoint-lg) {
      // background: #131313;
      border: 1px solid #2D2D2D;

      padding: 10px;
    }

    p {
      @include media(down, $breakpoint-lg) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

    }

    &:hover {
      background-color: #212121;
      border: 1px solid #303030;
    }
  }
  .feature-image {
    width: 40px;
    height: 40px;
  }
}

.mycoursedetails-page {
  .slick-slide {
    margin: 0 10px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -2px;
  }
}

.feature-card-wrap-slider {
  .feature-box {
    padding: 10px 26px;
    border-radius: 20px;
    // background: #212121;
    border: 1px solid $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      margin: 6px;
    }

    // @include media(down, $breakpoint-lg) {
    //   background: #131313;
    //   border: 1px solid #2D2D2D;
    //   border-radius: 20px;
    //   padding: 10px;
    // }
    &.border-primary-solid,
    &:hover {
      background-color: $white;
      border-color: $white !important;
      transition: all 0.5s ease;

      p {
        color: #7015FF !important;
      }
    }

    p {
      @include media(down, $breakpoint-lg) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      margin-bottom: 0;
      color: #dce1de;
      font-size: 16px;

      @include media(down, $breakpoint-lg) {
        font-size: 13px;
      }

    }

    &:hover {
      // background-color: #131313;
      // border: 1px solid #7015FF;
    }
    img {
      margin-bottom: 10px;
    }
  }
  .feature-image {
    width: 40px;
    height: 40px;
  }
}


.buy-subscription {
  color: $white;
}

.course-listing-table {

  tbody {
    tr {
      td {
        width: 50%;
        padding: 10px;

        &:last-child {
          border-right: 0;
        }

        ol {
          margin: 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.dst-view {
  display: flex;

  @include media(down, $breakpoint-md) {
    display: none;
  }
}

.mb-view {
  display: none;

  @include media(down, $breakpoint-md) {
    display: flex;
  }
}

.button-sgp {
  @include media(down, $breakpoint-sm) {
    margin-bottom: 15px;
  }
}

// categories slider
.categories-slider {
  // margin: auto;
  // width: 85%;
  // @include media(down, $breakpoint-sm){
  //   width: 100%!important;
  //       }
  .slick-slider {
    height: 135px;

    @include media(up, $breakpoint-xxxl) {
      margin: auto;
      padding-left: 70px;
    }

    .slick-arrow {
      transform: none !important;
    }

    .slick-next {
      // border: 1px solid #fff;
      width: 280px;
      height: 100%;
      border-radius: 0 10px 10px 0;
      // background: linear-gradient(to bottom, transparent 0%, black 100%) !important;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, transparent 17%, rgba(24, 24, 24, 1) 62%, rgba(24, 24, 24, 1) 84%) !important;
      bottom: auto;
      top: -10px;
      right: -42px;
      left: auto;
      background-position: center !important;
      // @include media(up, $breakpoint-xxxl){
      //    top: 9px;
      // }
      // @include media(down, $breakpoint-lg){
      //    top: 8px;
      //    right: -167px;
      // }
      // @include media(down, $breakpoint-sm){
      //    right: -200px;
      //    display: none !important;
      // }
      &::after {
        content: "\e90d";
        font-family: "icomoon";
        color: #fff;
        position: absolute;
        left: 81px;
        top: -30px;
        font-size: 15px;
        color: #fff;
        //  transform: translate(-50%);
        right: 0;
        //  @include media(up, $breakpoint-xxxl){
        //     top: 49%;
        //     transform: translate(-49%);
        //  }
        //  @include media(down, $breakpoint-sm){
        //     top: 52%;
        //     transform: translate(-49%);
        //  }
      }
      &::before {
        content: "";
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: -50px;
        left: 160px;
        //  transform: translate(-50%);
        background: #4d4d4d;
        border-radius: 100%;
        //  @include media(down, $breakpoint-sm){
        //     top: 42%;
        //  }
      }
      &:hover {
        &::after {
          color: #bababa;
        }
        &::before {
          background: #fff;
        }
      }
    }
    .slick-prev {
      // border: 1px solid #fff;
      width: 280px;
      height: 100%;
      border-radius: 0 10px 10px 0;
      // background: linear-gradient(to bottom, transparent 0%, black 100%) !important;
      //  background: rgb(255,255,255);
      //  background: linear-gradient(90deg, transparent 17%, rgba(24,24,24,1) 62%, rgba(24,24,24,1) 84%) !important;
      background: none !important;
      bottom: auto;
      top: -10px;
      right: -42px;
      left: auto;
      background-position: center !important;
      //  @include media(up, $breakpoint-xxxl){
      //     top: 9px;
      //  }
      //  @include media(down, $breakpoint-lg){
      //     top: 8px;
      //     right: -167px;
      //  }
      //  @include media(down, $breakpoint-sm){
      //     right: -200px;
      //     display: none !important;
      //  }
      &::after {
        content: "\e90f";
        font-family: "icomoon";
        color: #fff;
        position: absolute;
        left: -25px;
        top: -30px;
        font-size: 15px;
        color: #fff;
        //  transform: translate(-50%);
        right: 0;
        // @include media(up, $breakpoint-xxxl){
        //    top: 49%;
        //    transform: translate(-49%);
        // }
        // @include media(down, $breakpoint-sm){
        //    top: 52%;
        //    transform: translate(-49%);
        // }
      }
      &::before {
        content: "";
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: -50px;
        left: 108px;
        //  transform: translate(-50%);
        background: #4d4d4d;
        border-radius: 100%;
        // @include media(down, $breakpoint-sm){
        //    top: 42%;
        // }
      }
      &:hover {
        &::after {
          color: #bababa;
        }
        &::before {
          background: #fff;
        }
      }
    }
    .slick-slide {
      padding: 5px;
      margin: 0 2px;
      width: 160px;
    }
  }
}




.pricing-title {
  background: url('../../../../public/assets/images/green_back.png') no-repeat center center;
  background-size: cover;
  padding: 20px 15px 20px 35px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
}

.pricing-title-course {
  background: url('../../../../public/assets/images/green_back.png') no-repeat center center;
  background-size: cover;
  padding: 20px 15px 20px 35px;
  border-radius: 10px;
  font-weight: 600;
}


.button-14 {
  background: url('../../../../public/assets/images/green_back.png') no-repeat center center;
  background-size: cover;
  font-weight: 600;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  &:hover {
    color: #fff;
  }
}



.courses-btn-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .courses-btns {
    margin: 10px 0 10px 10px;

    &:first-child {
      margin-left: 0;
    }

    .btn {

      @include media(down, $breakpoint-xxxxxl) {
        min-width: 790px;
      }

      @include media(down, $breakpoint-xxxxl) {
        min-width: 640px;
      }

      @include media(down, $breakpoint-xxxl) {
        min-width: 550px;
      }

      @include media(down, $breakpoint-xxl) {
        min-width: 490px;
      }

      @include media(down, $breakpoint-xsl) {
        min-width: 800px;
      }

      @include media(down, $breakpoint-lg) {
        min-width: 150px;
      }

      @include media(down, $breakpoint-md) {
        min-width: 150px;
      }

      @include media(down, $breakpoint-sm) {
        min-width: 150px;
      }


    }

    @include media(down, $breakpoint-sm) {
      margin: 10px 0 10px 0px !important;
    }
  }

}


.wrapper-btn {
  width: auto;
}

.border-primary-solid {
  border: 1px solid $primary !important;
}

.border-primary-green {
  // border: 1px solid #089f45 !important;
  // background: #08A045;
  // &:hover{
  //   box-shadow:-1px 0 1px 1px rgba(112, 21, 255, 0.75), inset -1px 0 0 1px rgba(112, 21, 255, 0.75);
  // }
  color: $primary;
}

.ml-in-web {
  @include media(up, $breakpoint-xxl) {
    margin-left: 45px;
  }
}
.mw-in-web {
  @include media(up, $breakpoint-xxl) {
    max-width: 80%;
  }
}
.small_line_height {
  line-height: 30px !important;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  text-align: center;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 50px;
  font-size: 16px;
  color: #FFF;

}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  // background: url('../../../../public/assets/images/facebook.svg') 10px 6px no-repeat;
  background-size: 13px;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5 !important;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  background: $primary;
}

.loginBtn--google:before {

  background: url('../../../../public/assets/images/google.svg') 14px 8px no-repeat;
  background-size: 22px 22px;


}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background-color: transparent !important;
  border: 1px solid $primary;
}

.new_login_signup {
  padding: 0.5rem 27px !important;
  font-size: 1rem !important;
}

.display_in {
  display: inline-grid;
}

.d_none {
  display: none;
}

.min-w-100 {
  min-width: 100%;
}

.mob-w-100{
  @include media(down, $breakpoint-sm) {
    width: 100%;
  }
}
.desktop-btn-big{
  @include media(up, $breakpoint-lg) {
    min-width: 300px
  }
}

.mr-34 {
  margin-right: calc(80px - 1.875rem);
}

.min-h-38 {
  min-height: 38.92px;
}

a {
  &:hover {
    color: $white !important;
  }
}
.a-hover {
  &:hover {
    color: $black !important;
  }
}



.banner-login {
  z-index: 100;

  .em-label {
    text-align: left !important;
  }
}




.pr-block {
  margin-bottom: 40px;
  padding: 0 30px;

  @include media(down, $breakpoint-md) {
    padding: 0;
    margin-bottom: 20px;
  }

  .pr-body {
    padding: 15px;
    background-color: #3e3e3e;

    .pr-image {
      border: 1px solid #bababa;
      min-width: 250px;
      min-height: 250px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .pr-footer {
    background: $primary;
    padding: 25px;
    border-radius: 0 0 40px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.content-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  // z-index: -2;
  .container-fluid {
    position: relative;
    z-index: 2;
  }

  &:nth-child(odd) {
    background: url('../../../../public/assets/images/background-odd.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 525px;

    @include media(down, $breakpoint-md) {
      height: 100%;
    }

  }

  &:first-child {
    background: url('../../../../public/assets/images/background-one.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 525px;

    @include media(down, $breakpoint-md) {
      height: 100%;
    }

  }

  &:nth-child(even) {
    background: url('../../../../public/assets/images/background-even.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 525px;

    @include media(down, $breakpoint-md) {
      height: 100%;

      .book-images {
        left: 60%;
      }
    }

    .cnt-content {
      color: #212121;
    }

  }

  &.back-erik {
    background: url('../../../../public/assets/images/background-even.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 525px;
    min-height: 200px;
    @include media(down, $breakpoint-md) {
      height: 100%;
    }
  }

  &.cbanner3 {
    background: url('../../../../public/assets/images/background-odd.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 525px;
    min-height: 200px;

    @include media(down, $breakpoint-md) {
      height: 100%;
    }

  }

  @include media(down, $breakpoint-md) {
    background: none;
    flex-direction: column;
    // position: relative;
    // padding: 20px;
    // display: none;
  }
  // &.banner1{
  //   background: url('../../../../public/assets/images/cnt-banner1.jpeg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 100%;
  //   width: 100%;
  //   height: 525px;
  //   @include media(down, $breakpoint-md){
  //     background: none;
  //     height: 100%;
  //   }
  // }
  // &.banner2{
  //   background: url('../../../../public/assets/images/cnt-banner2.jpeg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 100%;
  //   width: 100%;
  //   height: 525px;
  //   @include media(down, $breakpoint-md){
  //     background: none;
  //     height: 100%;
  //   }
  // }
  // &.banner3{
  //   background: url('../../../../public/assets/images/cnt-banner3.jpeg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size:100%;
  //   width: 100%;
  //   height: 525px;
  //   @include media(down, $breakpoint-md){
  //     background: none;
  //     height: 100%;
  //   }
  // }
  .cnt-content {
    // position: relative;
    width: 50%;
    // padding: 30px 0;
    @include media(down, $breakpoint-md) {
      //  padding: 10px 0;
      width: 65%;
      display: flex;
      align-items: flex-start;
      // justify-content: center;
      flex-direction: column;
      margin: 15px 0;
      .cnts-btn {
        // display: none !important;
      }
    }
  }
  .book-images {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }

    @include media(down, $breakpoint-xsl) {
      right: auto;
      left: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
    @include media(down, $breakpoint-md) {
      width: 70%;
      right: auto;
      left: 35%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
    // @include media(down, $breakpoint-sm){
    //   width: 100%;
    //   right: -250px;
    // }
  }
}

    // @include media(down, $breakpoint-sm){
    //   width: 100%;
    //   right: -250px;
    // }  
//   }
// }

.cnt-with-image {
  @include media(down, $breakpoint-lg) {
    overflow: hidden;
  }
}


// .cnt-mobile-block{
//   display: none;
//   @include media(down, $breakpoint-md){
//     display: block;
//   }
//   .cnt-content{
//     position: relative;
//     .cnt-image{
//       position: absolute;
//       left: 0;
//       top: 0;
//       z-index: -1;
//       height: 100%;
//       width: 100%;
//       img{
//         width: 100%;
//         height: 100%;
//       }
//     }
//     .btn{
//       padding: 7px 16px;
//       font-size: 12px;
//     }
//     h2{
//       font-size: 18px;
//       line-height: 26px;
//     }
//     h4{
//       font-size: 14px;
//       line-height: 20px;
//     }
//   }
// }

.gray-background {
  background: #dbe0dc;
}

.ns-accordion {
  .accordion-item {
    background-color: transparent;
    border-color: #bababa;
    border-radius: 0;
    padding: 20px 0;
    border-right: 0;
    border-left: 0;
    border-top: 0;
  }
  .accordion-button {
    background-color: transparent;
    border-bottom: 0;
    border: 0;
    color: #000 !important;
    box-shadow: none;

    &:not(.collapsed) {
      box-shadow: none;

      &:after {
        background-image: none;
        transform: rotate(270deg);
        right: 0;
        top: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:after {
      content: "\e90d";
      font-family: "icomoon";
      position: absolute;
      right: 0;
      top: 10px;
      background-image: none;
      transform: rotate(90deg);
    }
  }
  .accordion-body {
    margin: 20px 0 20px 20px;
    background: #fff;
    border-radius: 8px;
    color: #000;
  }

}

.my-image2 {
  margin-left: 50px;
  width: 90% !important;

  @include media(up, $breakpoint-xxl) {
    width: 93% !important;
  }

  @include media(up, $breakpoint-xxxl) {
    width: 94% !important;
  }


}

.blog_image {
  // width: 188px;
  height: 188px;
  @include media(down, $breakpoint-sm) {
    // width: 88px;
    // height: 88px;
    padding: 10px;
  }
}

.blog-image {

  display: flex;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}

.ns-accordion {
  &.blog-accordion-wrapper {
    .accordion-button{
      color: #F5F7F9 !important;
      padding: 0;
      &::after{
        content: '';
        transform: rotate(360deg);
        top: 5px;
      }
      &:not(.collapsed) {
        &:after {
          transform: rotate(180deg);
        }
      }
      h4{
        @include media(down, $breakpoint-sm) {
          font-size: 16px;
        }
      }
    }
   .accordion-body{
      background:transparent;
      color: #F5F7F9;
      margin: 0;
      padding: 32px 16px 0;
   }
   .accordion-item{
      padding:32px 0;
      border-color: #F5F7F9;
      &:first-child{
        border-top: 0;
      }
      @include media(down, $breakpoint-sm) {
        padding:14px 0;
      }
   }
  }
}

.blg-acr-container {
  @media only screen and (min-width: 1600px) {
    max-width: 1060px !important;
  }
  .blogs-detailes{
    color: #F5F7F9;
    .btn{
      @include media(down, $breakpoint-sm) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.no-border-model {
  border-bottom: none !important;
}

.mobile-top {
  @include media(down, $breakpoint-md) {
    position: absolute;
    top: -25px;
  }
}
.mobile-img-block{
  @include media(down, $breakpoint-sm) {
    height: 178px;
  }
}

.mr-5 {
  margin-right: 5px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-40 {
  margin-right: 35px;
}
.footer_black {
  color: #000 !important;
}
.border-top-black {
  border-top: 1px solid #000 !important;
}
.contact_popup_h4 {
  color: $white !important;
/* H4 */
font-family: "Clash Grotesk";
font-size: 24px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
}
.contact_popup_sub_title {
  color: $white !important;

/* H5 */
font-family: "Clash Grotesk";
font-size: 20px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
opacity: 0.6 !important;
@include media(down, $breakpoint-sm) {
  font-size: 14px !important;
}
}
.no-bottom-border {
  border-bottom: none !important ; 
}

.modal-footer {
  border-top: none !important;
}
.custome_opicity {
  opacity: 0.4;
}
.border-none {
  border-bottom: none !important
}
.deadline_picker {
 input {
  width: 150px;
 }
}
.border-top-light {
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.left-text {
  text-align: left;
}
.podcast-button {
  @include media(down, $breakpoint-sm) {
  width: 100%;
  }
}
.justify-content-md-center {
  justify-content: center !important
}
.no_hover {
  &:hover {
    background-color: $white !important;
  }
}
.set-height {
  height: fit-content;
}
.w-20 {
  min-width: 20%;
}