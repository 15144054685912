.footer {
   // background: url('../../../../public/assets/images/footer-pattern.jpg');
   background-repeat: no-repeat;
   background-size: cover;
  
}

.footer-contactlist {
   li {
      margin-bottom: size(10);
   }
}

.footer-logo {
   max-width: 130px;
}

.footer-top {
   margin-bottom: 65px;

   @include media(down, $breakpoint-sm) {
      text-align: center;
   margin-bottom: 55px;
   }
}

.footerinfo-block {
   @include media(down, $breakpoint-sm) {
      width: 100%;
   }
}

.footer-link {
   display: inline-block;
   line-height: 1;
}

.footer-bottomblock {
   margin: 0 -15px;

   .footer-wrapper:nth-child(2),
   .footer-wrapper:nth-child(3) {
      @include media(down, $breakpoint-sm) {
         display: none;
      }
   }
}

.footer-wrapper {
   // max-width: calc(100% / 3);
   // padding: 0 15px;

}

.footer-ic {
   display: flex !important;
   align-items: center;
   justify-content: center;

   img {
      max-height: 100%;

      &:hover {
         filter: invert(65%) sepia(82%) saturate(5829%) hue-rotate(264deg) brightness(102%) contrast(114%);
      }
   }
}

.footer_new {
   background-repeat: no-repeat;
   background-size: cover;
}

.footer-informationlist {
   .footer-link {
      font-weight: 300;
      font-size: 20px;
   }
}